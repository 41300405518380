import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./navigation.scss";
import { AuthContext } from "../Context/AuthContext";

import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const urlSchema = {
  crm: ["affiliate-crm"],
  dashboard: ["dashboard", "edit-dashboard"],
  material: ["marketing-material"],
  profile: ["my-profile"],
  settings: ["settings"],
};

const AffiliateNavigation = ({ pathName, companyLogo, userImage }) => {
  const { t: locale } = useTranslation(["common", "pages"]);

  const { logOut } = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);

  // useEffect(() => {
  //   if (userData.authData) {
  //     console.log("UserData:", userData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userData]);

  const logOutUser = async () => {
    await logOut();
  };

  const navigationDrawerHide = () => {
    if (document.body.clientWidth <= 991) {
      setExpanded(expanded ? false : "expanded");
    }
  };
  /*******/
  return (
    <React.Fragment>
      <Navbar bg="dark" variant="dark" expand="lg" expanded={expanded}>
        <Link className="navbar-brand" to="/dashboard">
          <img src={companyLogo} alt="" />
        </Link>
        <Navbar.Toggle onClick={() => navigationDrawerHide()} />
        <Navbar.Collapse id="basic-navbar-nav" className={"primary-navbar"}>
          <Nav className="me-auto ms-xl-5 ps-xl-5">
            <Link
              to="/dashboard"
              className={`nav-link ${
                urlSchema["dashboard"].indexOf(pathName) > -1 ? "active" : ""
              }`}
              role="button"
              onClick={() => navigationDrawerHide()}
            >
              {locale("pages:navigation.Dashboard")}
            </Link>
            <Link
              to="/affiliate-crm"
              className={`nav-link ${
                urlSchema["crm"].indexOf(pathName) > -1 ? "active" : ""
              }`}
              role="button"
              onClick={() => navigationDrawerHide()}
            >
              {locale("pages:navigation.CRM")}
            </Link>
            <Link
              to="/marketing-material"
              className={`nav-link ${
                urlSchema["material"].indexOf(pathName) > -1 ? "active" : ""
              }`}
              role="button"
              onClick={() => navigationDrawerHide()}
            >
              {locale("pages:navigation.Marketing Material")}
            </Link>
          </Nav>
        </Navbar.Collapse>

        <Nav className="admin-action-navbar">
          <NavDropdown
            title={userImage}
            align="end"
            id="basic-nav-dropdown"
            className="admin-action-dropdown"
          >
            <NavDropdown.Item onClick={logOutUser}>
              <i className="ri-logout-circle-line"></i>{" "}
              {locale("pages:navigation.Logout")}
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>
    </React.Fragment>
  );
};

export default AffiliateNavigation;

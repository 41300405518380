import { gql } from "@apollo/client";

/** Check inventory of particular inventory type is exhausted or not **/
export const CHECK_INVENTORY_FOR_STATUS = gql`
  query checkInventoryStatus($team_id: Int, $inventory_type: String) {
    checkInventoryStatus(team_id: $team_id, inventory_type: $inventory_type) {
      status
      upgrade_available_for
    }
  }
`;

/** Check inventory of all inventory type is exhausted or not **/
export const ALL_INVENTORY_STATUS = gql`
  query allInventoryStatus($team_id: Int) {
    allInventoryStatus(team_id: $team_id) {
      status
      for_type
      upgrade_available_for
    }
  }
`;
import React from "react";
import { Row, Col, Button } from "react-bootstrap";

export const PaymentMethod = ({
  handleCloseAddContact,
  handleShowAddPaymentMethod,
  locale,
}) => {
  return (
    <>
      <Row className="align-items-center justify-content-center">
        <Col md="8" lg="7">
          <div className="setting-add-payment-method">
            <div className="payment-icon">
              <span>
                <i className="ri-bank-card-2-line"></i>
              </span>
            </div>
            <p>
              {locale("pages:setting.account.billing.No Payment Method Added")}
            </p>
            <Button
              variant="outline-primary"
              onClick={handleShowAddPaymentMethod}
            >
              <i className="ri-add-line"></i>{" "}
              {locale("pages:setting.account.billing.Add Payment Method")}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { isObjectEmpty } from "../../../Utils/utils";
import OverlayPopup from "./OverlayPopup";

const dashboardBg = {
  en: {
    backgroundImage:
      "url(" + require("../../../Images/Final_dashboard.png") + ")",
  },
  fr: {
    backgroundImage:
      "url(" + require("../../../Images/FinalFr_dashboard.png") + ")",
  },
  pt: {
    backgroundImage:
      "url(" + require("../../../Images/FinalPt_dashboard.png") + ")",
  },
};

const VerifyEmail = ({
  showPopup,
  email,
  changeEmailForUnconfirmed,
  fetchUserSettings,
  language,
  currentCompanySetting,
  isUserActive = false,
}) => {
  const [lang, setLang] = useState("en");

  useEffect(() => {
    if (language) {
      setLang(language);
    } else if (
      currentCompanySetting &&
      !isObjectEmpty(currentCompanySetting.language)
    ) {
      setLang(currentCompanySetting.language);
    }
  }, [language, currentCompanySetting]);

  /*****/
  return (
    <div className="verify-email" style={dashboardBg[lang]}>
      <OverlayPopup
        showPopup={showPopup}
        changeEmailForUnconfirmed={changeEmailForUnconfirmed}
        email={email}
        fetchUserSettings={fetchUserSettings}
        isUserActive={isUserActive}
      />
    </div>
  );
};
export default VerifyEmail;

import { gql } from "@apollo/client";
/** Announcement listing **/
export const ANNOUNCEMENT_LIST = gql`
  query (
    $team_id: Int
    $first: Int!
    $page: Int
    $field: String!
    $order: SortOrder!
  ) {
    announcementsListing(
      team_id: $team_id
      first: $first
      page: $page
      orderBy: [{ column: $field, order: $order }]
    ) {
      data {
        id
        title
        urgent
        description
        start_date
        end_date
        created_at
        updated_at
        role_type
        view_count
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

export const ANNOUNCEMENT_DELETE = gql`
  mutation deleteAnnouncement($id: Int, $team_id: Int) {
    deleteAnnouncement(id: $id, team_id: $team_id) {
      status
    }
  }
`;

export const ANNOUNCEMENT_CREATE = gql`
  mutation createAnnouncement(
    $team_id: Int
    $title: String
    $description: String
    $urgent: Int
    $start_date: DateTime
    $end_date: DateTime
    $notify_to: [String]
  ) {
    createAnnouncement(
      team_id: $team_id
      start_date: $start_date
      end_date: $end_date
      urgent: $urgent
      description: $description
      title: $title
      role_type: $notify_to
    ) {
      id
      title
      user_id
      description
      urgent
      start_date
      end_date
      role_type
    }
  }
`;
export const ANNOUNCEMENT_UPDATE = gql`
  mutation updateAnnouncement(
    $id: Int
    $team_id: Int
    $title: String
    $description: String
    $urgent: Int
    $start_date: DateTime
    $end_date: DateTime
    $notify_to: [String]
  ) {
    updateAnnouncement(
      id: $id
      team_id: $team_id
      start_date: $start_date
      end_date: $end_date
      urgent: $urgent
      description: $description
      title: $title
      role_type: $notify_to
    ) {
      id
      title
      user_id
      description
      urgent
      start_date
      end_date
      role_type
    }
  }
`;
export const ANNOUNCEMENTS = gql`
  query allAnnouncements($team_id: Int) {
    allAnnouncements(team_id: $team_id) {
      id
      title
      urgent
      description
      start_date
      end_date
      created_at
      updated_at
    }
  }
`;

export const ANNOUNCEMENTS_VIEW_LIST = gql`
  query announcementViewListing(
    $team_id: Int
    $id: Int
    $first: Int!
    $page: Int
    $field: String!
  ) {
    announcementViewListing(
      team_id: $team_id
      first: $first
      page: $page
      orderBy: [{ field: $field, order: DESC }]
      id: $id
    ) {
      data {
        first_name
        last_name
        pivot {
          created_at
        }
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;
export const ANNOUNCEMENTS_VIEW = gql`
  mutation announcementView($team_id: Int, $announcementsIds: [Int]) {
    announcementView(team_id: $team_id, announcement_id: $announcementsIds) {
      status
    }
  }
`;

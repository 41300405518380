import React from "react";
import "./media.css";
import MediaBody from "./MediaBody";

export const Media = ({ children, className = null }) => {
  return (
    <div className={className ? className + " media" : "media"}>{children}</div>
  );
};

Media.Body = MediaBody;

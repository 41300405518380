import { gql } from "@apollo/client";

export const AFFILIATES_CREATE = gql`
  mutation createAffiliate(
    $first_name: String
    $last_name: String
    $email: String
    $commission: Int
    $team_id: Int
  ) {
    createAffiliate(
      team_id: $team_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      commission: $commission
    ) {
      id
      first_name
      last_name
      email
      created_at
      updated_at
    }
  }
`;

export const AFFILIATES_LIST = gql`
  query getAffiliates($first: Int!, $page: Int, $team_id: Int) {
    getAffiliates(first: $first, page: $page, team_id: $team_id) {
      data {
        id
        first_name
        last_name
        email
        commission
        owned_user_count
        created_at
        updated_at
        unique_id
        slug
        status
        total_commission
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

export const AFFILIATES_SET_PASSWORD = gql`
  mutation setAffiliatePassword($unique_code: String, $password: String) {
    setAffiliatePassword(unique_code: $unique_code, password: $password) {
      id
      first_name
      last_name
      email
      unique_id
      slug
      commission
      token
    }
  }
`;

export const GET_AFFILIATES = gql`
  query getAffiliate($s: String) {
    getAffiliate(s: $s) {
      id
      first_name
      last_name
      email
      unique_id
      slug
      commission
      token
    }
  }
`;

export const AFFILIATE_DELETE = gql`
  mutation deleteAffiliate($id: String, $team_id: Int) {
    deleteAffiliate(unique_id: $id, team_id: $team_id) {
      status
    }
  }
`;

export const AFFILIATES_UPDATE = gql`
  mutation updateAffiliate(
    $unique_id: String
    $first_name: String
    $last_name: String
    $email: String
    $commission: Int
    $team_id: Int
  ) {
    updateAffiliate(
      unique_id: $unique_id
      team_id: $team_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      commission: $commission
    ) {
      id
      first_name
      last_name
      email
      created_at
      updated_at
    }
  }
`;
export const AFFILIATES_ME = gql`
  query affiliateMe {
    affiliateMe {
      id
      first_name
      last_name
      email
      unique_id
      slug
      commission
      token
    }
  }
`;

export const AFFILIATES_DASHBOARD = gql`
  query getAffiliateDashboardData {
    getAffiliateDashboardData {
      total_leads
      on_paid_plan
      on_free_plan
      total_revenue
      commission_earned
    }
  }
`;

export const AFFILIATES_CONTACT_LIST = gql`
  query getAffiliateContacts($first: Int!, $page: Int, $search: String) {
    getAffiliateContacts(first: $first, page: $page, search: $search) {
      data {
        first_name
        last_name
        name
        email
        created_at
        lastActiveSubscription {
          subscription_status
          canceled_date
          trial_expiry_date
          price
          plan {
            name
          }
        }
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
  }
`;

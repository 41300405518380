import React, { useEffect } from "react";
import {
  NOTIFICATIONS,
  NOTIFICATIONS_SUBSCRIPTION,
} from "../GraphQueries/Users/Notifications";
import { useQuery, useSubscription } from "@apollo/client";
import {
  ENVIRONMENT,
  currentTeamId,
  getCookie,
  isObjectEmpty,
} from "../Utils/utils";

export const CallNotifications = ({
  fetchNewNotification = () => {},
  setNewNotifications = () => {},
  setUnreadCount = () => {},
  setIsLoading = () => {},
  setIsError = () => {},
  isLoading,
  isError,
  newNotifications,
}) => {
  const hasInterval =
    ENVIRONMENT !== "local-frontend" && !isObjectEmpty(getCookie("idToken"))
      ? 5000
      : 0;

  /**** Notification Subscription ****/
  const { data, error } = useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
    variables: { team_id: currentTeamId },
    skip: process.env.REACT_APP_NOTIFICATION_MODE === "pusher" ? false : true,
  });

  const { data: notifications, loading: notificationLoading } = useQuery(
    NOTIFICATIONS,
    {
      variables: {
        team_id: currentTeamId,
      },
      fetchPolicy: "no-cache",
      ...(process.env.REACT_APP_NOTIFICATION_MODE === "polling"
        ? { pollInterval: hasInterval }
        : {}),
    }
  );

  useEffect(() => {
    if (error && error !== isError) {
      setIsError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setIsLoading(notificationLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationLoading]);

  useEffect(() => {
    if (data && data.notificationCreated) {
      fetchNewNotification(data.notificationCreated.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (
      notifications &&
      notifications.getNotifications &&
      notifications.getNotifications.unread_notification_count > 0
    ) {
      setNewNotifications(notifications.getNotifications.notifications);
      setUnreadCount(notifications.getNotifications.unread_notification_count);
    } else if (notifications && notifications.getNotifications) {
      setNewNotifications([]);
      setUnreadCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return <React.Fragment></React.Fragment>;
};

import { gql } from "@apollo/client";

/** Duplicates listing **/
export const FETCH_DUPLICATE_ENTRIES = gql`
  query fetchDuplicateEntries($team_id: Int) {
    fetchDuplicateEntries(team_id: $team_id) {
        id
        duplicate_type
        duplicate_value
        is_skipped
        is_fixed
        contacts {
        id
        name
        email
        mobile
                image {
            path
        }
        }
        created_at
        updated_at
    }
  }
`;

/**  Duplicate contact deletion   */
export const REMOVE_DUPLICATES = gql`
  mutation removeDuplicates($teamId: Int, $contactId: Int, $duplicateId: Int) {
    removeDuplicates(team_id: $teamId, contact_id: $contactId, duplicate_id: $duplicateId) {
      status
    }
  }
`;
import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";
import { InventoryConsumption } from "../../../Context/InventoryConsumption";

export const BuyAddonPlan = () => {
  const { t: locale } = useTranslation();
  const {
    showBuyAddonPlan,
    inventoryType,
    upgradeAvailableFor,
    setShowBuyAddonPlan,
    buttonSelection,
    setButtonSelection,
    redirectTo,
    setRedirectTo,
  } = useContext(InventoryConsumption);
  const buyAddonPlan = async (value) => {
    await setButtonSelection(value);
    await setRedirectTo(true);
  };

  const hideModal = () => {
    setShowBuyAddonPlan(false);
  };

  if (redirectTo) {
    return (
      <Navigate
        to="/settings/accounts-and-billing"
        state={{
          redirectPageParams: {
            activeTab: "accounts-and-billing",
            subTab: buttonSelection,
          },
        }}
      />
    );
  }
  return (
    <Modal show={showBuyAddonPlan} onHide={hideModal} style={{ zIndex: 9999 }}>
      <Modal.Header closeButton>
        <Modal.Title>{locale("Inventory Consumption")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {locale(
          "You have reached the limit for {inventoryType}. You can choose one of the following options",
          {
            inventoryType:
              inventoryType === "document"
                ? "space"
                : inventoryType
                ? inventoryType.replace("_", " ")
                : "",
          }
        )}
        ;
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          {locale("Do Later")}
        </Button>
        {upgradeAvailableFor && upgradeAvailableFor === "addon" && (
          <Button variant="primary" onClick={() => buyAddonPlan("addons")}>
            {locale("Buy Addon")}
          </Button>
        )}
        <Button variant="primary" onClick={() => buyAddonPlan("plan")}>
          {locale("Upgrade Plan")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import { gql } from "@apollo/client";

/** Contact listing for verify **/
export const UNVERIFIED_CONTACTS_LIST = gql`
  query getTeamNotification($team_id: Int) {
    getTeamNotification(team_id: $team_id) {
      id
      notification_type
      other_information
      checked
      created_at
      updated_at
    }
  }
`;

export const CREDIT_USAGE = gql`
  query fetchCreditUsage($team_id: Int) {
    fetchCreditUsage(team_id: $team_id) {
      available_credits
      credit_usage
    }
  }
`;
export const INITIATE_EMAIL_VERIFY = gql`
  mutation initiateEmailVerification(
    $team_id: Int
    $team_notification_id: Int
  ) {
    initiateEmailVerification(
      team_id: $team_id
      team_notification_id: $team_notification_id
    ) {
      status
    }
  }
`;

export const VERIFICATION_EMAIL_LIST = gql`
  query getContactsVerificationList(
    $team_id: Int
    $first: Int!
    $page: Int
    $team_notification_id: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $status: String
  ) {
    getContactsVerificationList(
      team_id: $team_id
      first: $first
      page: $page
      team_notification_id: $team_notification_id
      search: $search
      orderBy: [{ column: $field, order: $order }]
      status: $status
    ) {
      data {
        id
        team_id
        contact {
          id
          name
          email
          deleted_at
        }
        status
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;

export const DELETE_INVALID_CONTACTS = gql`
  mutation deleteInvalidContacts($team_id: Int, $team_notification_id: Int) {
    deleteInvalidContacts(
      team_id: $team_id
      team_notification_id: $team_notification_id
    ) {
      status
    }
  }
`;

export const BUY_CREDITS = gql`
  mutation buyCredits(
    $teamId: Int
    $creditQuantity: Int
    $creditPrice: Float
    $autoRefillCredits: Boolean
    $autoBuyCreditsCount: Int
  ) {
    buyCredits(
      team_id: $teamId
      credit_quantity: $creditQuantity
      credit_price: $creditPrice
      auto_refill_credits: $autoRefillCredits
      auto_buy_credits_count: $autoBuyCreditsCount
    ) {
      id
      credit_change
      credit_change_on
      created_at
      updated_at
    }
  }
`;

/**** ALL NOTIFICATION LIST ***/
export const TEAM_NOTIFICATION_LIST = gql`
  query getTeamNotifications($team_id: Int, $first: Int!, $page: Int) {
    getTeamNotifications(team_id: $team_id, first: $first, page: $page) {
      data {
        id
        notification_type
        other_information
        checked
        created_at
        updated_at
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;

/**** ALL NOTIFICATION LIST ***/
export const VERIFICATION_STATS = gql`
  query getVerificationListStats($team_id: Int, $team_notification_id: Int) {
    getVerificationListStats(
      team_id: $team_id
      team_notification_id: $team_notification_id
    ) {
      msg
    }
  }
`;

/**** ALL NOTIFICATION LIST ***/
export const CREATE_EMAIL_VERIFICATION = gql`
  mutation createEmailVerification($team_id: Int) {
    createEmailVerification(team_id: $team_id) {
      status
    }
  }
`;

/**** ALL NOTIFICATION LIST ***/
export const EMAIL_LIST_BY_CAMPAIGN = gql`
  query emailListingByCampaign(
    $team_id: Int
    $first: Int!
    $page: Int
    $campaign_id: Int
    $search: String
    $order: SortOrder!
    $field: String!
    $start_date: DateTime
    $end_date: DateTime
  ) {
    emailListingByCampaign(
      team_id: $team_id
      first: $first
      page: $page
      campaign_id: $campaign_id
      search: $search
      orderBy: [{ column: $field, order: $order }]
      start_date: $start_date
      end_date: $end_date
    ) {
      data {
        id
        #message_id
        subject
        status
        scheduled_at
        logs
        contacts {
          id
          name
          email
        }
        last_status {
          name
        }
        created_at
        updated_at
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;
/**** ALL NOTIFICATION LIST ***/
export const UPDATE_EMAIL_VERIFICATION = gql`
  mutation updateEmailVerification($team_notification_id: Int, $team_id: Int) {
    updateEmailVerification(
      team_notification_id: $team_notification_id
      team_id: $team_id
    ) {
      status
    }
  }
`;

export const EMAIL_SENT_TO_CONTACT = gql`
  query contactEmailSentListing(
    $team_id: Int
    $first: Int!
    $page: Int
    $contact_id: Int
    $search: String
    $start_date: DateTime
    $end_date: DateTime
    $order: SortOrder!
    $field: String!
  ) {
    contactEmailSentListing(
      team_id: $team_id
      first: $first
      page: $page
      contact_id: $contact_id
      search: $search
      start_date: $start_date
      end_date: $end_date
      orderBy: [{ column: $field, order: $order }]
    ) {
      data {
        id
        #message_id
        subject
        status
        scheduled_at
        logs
        #contacts {
        #  id
        #  name
        #  email
        #}
        last_status {
          name
        }
        created_at
        updated_at
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;

import { Modal } from "react-bootstrap";
import { AddCard as AddCardModal } from "../../../Components/Pages/Settings/AccountAndBilling/AddCard";
import { useEffect, useState } from "react";
import { isObjectEmpty } from "../../../Utils/utils";
import { useNavigate } from "react-router-dom";

const dashboardBg = {
  en: {
    backgroundImage:
      "url(" + require("../../../Images/Final_dashboard.png") + ")",
  },
  fr: {
    backgroundImage:
      "url(" + require("../../../Images/FinalFr_dashboard.png") + ")",
  },
  pt: {
    backgroundImage:
      "url(" + require("../../../Images/FinalPt_dashboard.png") + ")",
  },
};

export const AddCard = ({ language, currentCompanySetting }) => {
  const navigate = useNavigate();
  const [lang, setLang] = useState("en");
  useEffect(() => {
    if (language) {
      setLang(language);
    } else if (
      currentCompanySetting &&
      !isObjectEmpty(currentCompanySetting.language)
    ) {
      setLang(currentCompanySetting.language);
    }
  }, [language, currentCompanySetting]);
  return (
    <div className="verify-email" style={dashboardBg[lang]}>
      <Modal
        className={"common modalLg"}
        show={true}
        onHide={() => {}}
        backdrop={"static"}
        keyboard={false}
      >
        <AddCardModal
          hideClose={true}
          handleCloseAddPaymentMethod={() => {
           window.location.reload();
          }}
        />
      </Modal>
    </div>
  );
};

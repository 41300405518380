import React from "react";

import { Row, Col, Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const ImportSummary = ({ data }) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  return (
    <>
      <Modal.Header closeVariant="white" closeButton className={"bg-dark"}>
        <Modal.Title className={"text-white secondary-font text-uppercase"}>
          {locale("messages:import.Import Summary")}
        </Modal.Title>
      </Modal.Header>
      {data !== null ? (
        <Modal.Body>
          <Row className="justify-content-md-center">
            <Col md="6" className={"mb-4"}>
              <Card className={"text-center shadow rounded"}>
                <Card.Body>
                  {data.type === "CONTACTS" && (
                    <h5> {locale("messages:import.Total Contacts")}</h5>
                  )}
                  {data.type === "DEALS" && (
                    <h5> {locale("messages:import.Total Deals")}</h5>
                  )}
                  <h2 className={"font-weight-bold text-success"}>
                    {data.total ? (data.total > 0 ? data.total : 0) : 0}
                  </h2>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6" className={"mb-4"}>
              <Card className={"text-center shadow rounded"}>
                <Card.Body className="px-1" style={{ height: "120px" }}>
                  <Row>
                    <Col>
                      {data.type === "CONTACTS" && (
                        <h6> {locale("messages:import.Created contacts")}</h6>
                      )}
                      {data.type === "DEALS" && (
                        <h6> {locale("messages:import.Created deals")}</h6>
                      )}
                      <h3 className={"font-weight-bold text-primary"}>
                        {data.inserted ? data.inserted : 0}
                      </h3>
                    </Col>
                    <Col className={"border-left"}>
                      {data.type === "CONTACTS" && (
                        <h6> {locale("messages:import.Overwrite contacts")}</h6>
                      )}
                      {data.type === "DEALS" && (
                        <h6> {locale("messages:import.Overwrite deals")}</h6>
                      )}
                      <h3 className={"font-weight-bold text-primary"}>
                        {data.overwrite ? data.overwrite : 0}
                      </h3>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" className={"mb-4"}>
              <Card className={"text-center shadow rounded"}>
                <Card.Body className="px-1" style={{ height: "120px" }}>
                  <Row>
                    <Col>
                      {data.type === "CONTACTS" && (
                        <h6> {locale("messages:import.Ignored contacts")}</h6>
                      )}
                      {data.type === "DEALS" && (
                        <h6> {locale("messages:import.Ignored deals")}</h6>
                      )}
                      <h3 className={"font-weight-bold text-primary"}>
                        {data.ignored
                          ? data.ignored > 0
                            ? data.ignored
                            : 0
                          : 0}
                      </h3>
                    </Col>
                    <Col className={"border-left"}>
                      {data.type === "CONTACTS" && (
                        <h6> {locale("messages:import.Duplicate contacts")}</h6>
                      )}
                      {data.type === "DEALS" && (
                        <h6> {locale("messages:import.Duplicate deals")}</h6>
                      )}
                      <h3 className={"font-weight-bold text-primary"}>
                        {data.duplicate ? data.duplicate : 0}
                      </h3>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      ) : (
        <Modal.Body> {locale("messages:import.Nothing To show!")}</Modal.Body>
      )}
    </>
  );
};

import { gql } from "@apollo/client";

/** Equp Plans listing **/
export const EQUP_PLAN_LIST = gql`
  query getEqupPlans($team_id: Int) {
    getEqupPlans(team_id: $team_id) {
      id
      name
      description
      trial_period
      billing_cycle
      visibility
      no_of_billing
      status
      default
      price
    }
  }
`;

/** Subscribed Plan Detail **/
export const EQUP_SUBSCRIBED_PLAN = gql`
  query getSubscribedPlan($team_id: Int) {
    getSubscribedPlan(team_id: $team_id) {
      plan {
        id
        price
        equpPlan {
          id
          name
          billing_cycle
          price
          trial_period
          status
        }
      }
      payment {
        amount
        paid
      }
      subscription_id
      price
      actual_amount_charged
      starts_at
      expiry_date
      trial_expiry_date
    }
  }
`;
/** Upgrade plan **/
export const EQUP_PLAN_UPGRADE = gql`
  mutation upgradeSubscription(
    $team_id: Int
    $plan_id: Int
    $billing_type: String
    $coupon: String
    $nameOnCard: String
    $card_number: String
    $month: String
    $year: String
    $cvv: String
    $card_type: String
    $billing_address: ContactBillingAddress
  ) {
    upgradeSubscription(
      team_id: $team_id
      plan_id: $plan_id
      billing_type: $billing_type
      billing_address: $billing_address
      coupon: $coupon
      card_name: $nameOnCard
      card_number: $card_number
      month: $month
      year: $year
      cvv: $cvv
      card_type: $card_type
    ) {
      plan_id
      #   subscription_id
      next_billing_date
    }
  }
`;

export const EQUP_LAYOUTS = gql`
  query equpLayout($team_id: Int) {
    equpLayout(team_id: $team_id) {
      id
      layout_id
      team_id
      name
      plans
      publish
      layout_items {
        id
        plan_id
        plan {
          id
          name
          price
          yearly_price
          billing_type
          no_of_billings
          trial_period
          fee_name
          fee_value
          fee_charge_on
          equpPlan {
            id
          }
        }
      }
      created_at
      updated_at
    }
  }
`;

export const EQUP_WALLET = gql`
  query getWallet($team_id: Int) {
    getWallet(team_id: $team_id) {
      credit
    }
  }
`;

export const EXTEND_PLAN_REQUEST = gql`
  mutation setTrialExtendRequest($team_id: Int, $description: String) {
    setTrialExtendRequest(team_id: $team_id, description: $description) {
      status
    }
  }
`;

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PageNotFound = ({ error = 404, setLoading = () => {} }) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const [pageError] = useState({
    404: { message: locale("messages:Permissions.page_not_found") },
    101: { message: locale("messages:Permissions.access_denied") },
    403: { message: locale("messages:Permissions.forbidden") },
  });

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="container"
      style={{ textAlign: "center", margin: "100px auto" }}
    >
      <h1>
        Error <>{error ? error : ""}</>
      </h1>
      <h3>{error && pageError[error] ? pageError[error].message : ""}</h3>
    </div>
  );
};

/********/
export const PermissionDenied = ({ message = null }) => {
  const { t: locale } = useTranslation(["common", "pages"]);

  if (message === null) {
    message = locale("messages:Permissions.view_error");
  }
  return (
    <Container style={{ textAlign: "center", margin: "100px auto" }}>
      <Row>
        <Col className="border border-warning p-3">
          <h3>{message}</h3>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;

import React from "react";
import PropTypes from "prop-types";

const alertType = {
  error: { variant: "text-danger", icon: "ri-alert-line", title: "Error!" },
  success: {
    variant: "text-success",
    icon: "ri-checkbox-circle-line",
    title: "Success",
  },
  warning: {
    variant: "text-warning",
    icon: "ri-alert-line",
    title: "Warning!",
  },
};

const InLineError = ({ type, message, extraStyle={} }) => {
  /** Style **/
  const style = {
    position: "absolute",
    ...extraStyle
  };
  return (
    <React.Fragment>
      <span
        className={"small " + alertType[type].variant}
        style={style}

      >
        {message ? message : ""}
      </span>
    </React.Fragment>
  );
};

InLineError.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

export default InLineError;

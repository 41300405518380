import React from "react";

const MediaBody = ({ children, className = null }) => {
  return (
    <div
      className={
        className ? className + " media-body m-2 mt-0" : "media-body m-2 mt-0"
      }
    >
      {children}
    </div>
  );
};
export default MediaBody;

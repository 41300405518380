import React, { useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";

import { AddPaymentMethod } from "./AddPaymentMethod";
import { PaymentMethod } from "./PaymentMethod";
import {
  cardTypes,
  getValidationMessage,
  toWordCase,
} from "../../../../Utils/utils";
import { useMutation } from "@apollo/client";
import { REMOVE_PAYMENT_METHOD } from "../../../../GraphQueries/Inventory";
import ConfirmationMessage from "../../../Common/AlertMessage/ConfirmationMessage";

export const Billing = ({
  paymentMethodList,
  addNewPaymentMethod,
  handleAlertMessage,
  getAddons,
  addressList,
  teamId,
  makeDefaultPaymentMethod,
  locale,
}) => {
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const handleCloseAddPaymentMethod = () => setShowAddPaymentMethod(false);
  const handleShowAddPaymentMethod = () => setShowAddPaymentMethod(true);
  /** Confirmation  Message State **/
  const [confirmation, setConfirmation] = useState({
    visible: false,
    message: "",
    type: "confirm",
    recordType: "Payment Method",
  });
  /*****/
  const [deletePaymentMethod] = useMutation(REMOVE_PAYMENT_METHOD);

  /****Delete Confirmation****/
  const handleConfirmationPopup = (value) => {
    setConfirmation({
      ...confirmation,
      visible: true,
      value: value,
    });
  };

  const makeDefault = (id) => {
    makeDefaultPaymentMethod({
      variables: { team_id: teamId, id: parseInt(id) },
      update: () => getAddons(),
    })
      .then((result) => {
        handleAlertMessage(
          "success",
          locale("messages:accountAndBilling.Payment method set to default.")
        );
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
      });
  };

  const removePaymentMethod = (id) => {
    deletePaymentMethod({
      variables: { team_id: teamId, id: parseInt(id) },
      update: () => getAddons(),
    })
      .then((result) => {
        handleAlertMessage(
          "success",
          locale("messages:common.delete", {
            fieldName: locale("Payment method"),
          })
        );
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
      });
  };
  /******/
  return (
    <React.Fragment>
      <Row className="justify-content-between align-items-center mb-4 ">
        <Col>
          <h3>{locale("pages:setting.account.billing.Payment Methods")}</h3>
        </Col>
        {paymentMethodList &&
        paymentMethodList.paymentMethodList &&
        paymentMethodList.paymentMethodList.length > 0 ? (
          <Col xs="auto">
            <Button
              variant="outline-primary"
              onClick={handleShowAddPaymentMethod}
            >
              <i className="ri-add-line"></i>{" "}
              {locale("pages:setting.account.billing.Add Payment Method")}
            </Button>
          </Col>
        ) : (
          ""
        )}
      </Row>
      {paymentMethodList &&
      paymentMethodList.paymentMethodList &&
      paymentMethodList.paymentMethodList.length > 0 ? (
        paymentMethodList.paymentMethodList.map((item, idx) => (
          <Row key={idx}>
            <Col>
              <div className="saved-card-details">
                <div className="saved-card-detail-list">
                  {item && !item.default && (
                    <Button
                      variant="link"
                      className="m-0 p-2 text-default"
                      style={{ position: "absolute", right: "0px" }}
                      onClick={() => handleConfirmationPopup(item.id)}
                    >
                      <i className="ri-close-line ri-xl ms-2" />
                    </Button>
                  )}
                  <input
                    type="radio"
                    name="choose_card"
                    id="masterCard"
                    checked={item && item.default}
                    onChange={() => makeDefault(item.id)}
                  />
                  <label htmlFor="masterCard">
                    <div className="card-details-left">
                      <span>
                        {cardTypes[item.card_type.toLowerCase()] && (
                          <img
                            src={cardTypes[item.card_type.toLowerCase()]}
                            alt={item.card_type}
                          />
                        )}
                      </span>
                      <div className="mb-2 mb-md-0 me-md-2">
                        {item.card_type === "unknown" && (
                          <h6>{toWordCase(item.card_type) + " Card"}</h6>
                        )}
                        <h5>{item.name ? item.name : ""} </h5>
                        <h5>{"**** **** **** " + item.card_number} </h5>
                      </div>
                      <div>
                        <h6>
                          {locale("pages:setting.account.billing.Exp. Date")}
                        </h6>
                        <h5>{item.expiry_date}</h5>
                      </div>
                    </div>
                    {item && item.default && (
                      <div className="default-card">
                        {locale("pages:setting.account.billing.Default")}
                      </div>
                    )}
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        ))
      ) : (
        <PaymentMethod
          handleCloseAddContact={handleCloseAddPaymentMethod}
          handleShowAddPaymentMethod={handleShowAddPaymentMethod}
          locale={locale}
        />
      )}
      <Modal
        className={"common modalLg"}
        show={showAddPaymentMethod}
        onHide={handleCloseAddPaymentMethod}
        backdrop={"static"}
        keyboard={false}
      >
        <AddPaymentMethod
          handleCloseAddPaymentMethod={handleCloseAddPaymentMethod}
          addNewPaymentMethod={addNewPaymentMethod}
          handleParentAlertMessage={handleAlertMessage}
          addressList={addressList}
          teamId={teamId}
          getAddons={getAddons}
          locale={locale}
        />
      </Modal>
      {/** Delete Confirmation Box **/}
      <ConfirmationMessage
        confirmation={confirmation}
        setConfirmation={setConfirmation}
        callback={removePaymentMethod}
      />
    </React.Fragment>
  );
};

import React, { useEffect, useState } from "react";
import { DescriptionLoader } from "../../Loader/SkeletonLoader";
import { BuyMoreCredits } from "./BuyMoreCredits";
import { useTranslation } from "react-i18next";
import {
  CREDIT_USAGE,
  INITIATE_EMAIL_VERIFY,
} from "../../../../GraphQueries/VerifyContactEmail";
import { useLazyQuery, useMutation } from "@apollo/client";
import { currentTeamId } from "../../../../Utils/utils";
import { Button, Modal } from "react-bootstrap";

export const FetchCreditAndInitiateVerification = ({
  confirmVerify,
  setConfirmVerify,
  credits,
  setCredits,
  unverifiedData,
  getUnverifiedData,
  currentCompanySetting,
}) => {
  const { t: locale } = useTranslation(["common", "messages"]);

  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [showAddCredits, setShowAddCredits] = useState(false);
  const [latestAvailableCredits, setLatestAvailableCredits] = useState(0);

  const [fetchCreditUsage, { loading }] = useLazyQuery(CREDIT_USAGE, {
    fetchPolicy: "no-cache",
  });
  const [initiateEmailVerification] = useMutation(INITIATE_EMAIL_VERIFY);
  /*****/
  useEffect(() => {
    setShowVerifyPopup(confirmVerify);
    if (confirmVerify) {
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmVerify]);

  const handleCloseBuyCredits = () => {
    setShowAddCredits(false);
    handleClick();
  };

  /** Check Credits **/
  const handleClick = () => {
    fetchCreditUsage({
      variables: { team_id: currentTeamId },
      fetchPolicy: "no-cache",
    })
      .then((result) => {
        setLatestAvailableCredits(
          result.data.fetchCreditUsage.available_credits
        );
        setCredits(result.data.fetchCreditUsage);
        setConfirmVerify(true);
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        console.log("error:", graphQLErrors);
      });
  };

  const handleInitiate = () => {
    initiateEmailVerification({
      variables: {
        team_id: currentTeamId,
        team_notification_id: parseInt(unverifiedData.processId),
      },
    })
      .then((result) => {
        setCredits({
          ...credits,
          status: result.data.initiateEmailVerification.status,
        });
        getUnverifiedData();
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        console.log("error:", graphQLErrors);
      });
  };
  /********/
  return (
    <React.Fragment>
      <Modal
        className={"common"}
        show={showVerifyPopup}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className={"bg-dark"}>
          <Modal.Title className={"text-white secondary-font text-uppercase"}>
            {locale("Verify Emails")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading || credits === null ? (
            <DescriptionLoader />
          ) : (
            <div className={"alert-in-popup alert-in-danger"}>
              <div className={"alert-in-popup-inner"}>
                {credits.status !== undefined && credits.status ? (
                  <p>
                    {locale(
                      "messages:notification.Email verification process has been initiated."
                    )}
                  </p>
                ) : credits.credit_usage === 0 ? (
                  <p className="mt-1">
                    {locale(
                      "messages:notification.We found no records in your CRM to validate."
                    )}
                  </p>
                ) : (
                  <React.Fragment>
                    <p className="mt-1">
                      {locale(
                        "messages:notification.We found {credits.credit_usage} records in your CRM which are not validated to send emails. To ensure the email address is valid it needs to be verified.",
                        { credits: credits }
                      )}
                    </p>
                    <p className="font-weight-bold">
                      {locale(
                        "messages:notification.To verify {credit_usage} records, you need {credit} Credits.",
                        {
                          credit:
                            credits.credit_usage > 4
                              ? parseInt(credits.credit_usage / 5)
                              : credits.credit_usage > 0
                              ? 1
                              : 0,
                          credit_usage: credits.credit_usage,
                        }
                      )}
                    </p>
                    <p>
                      {locale(
                        "messages:notification.Your Credit Balance {available_credits} Credits",
                        { available_credits: credits.available_credits }
                      )}
                      {credits !== null && (
                        <ShowButton
                          credits={credits}
                          handleInitiate={() => handleInitiate()}
                          setShowAddCredits={setShowAddCredits}
                          locale={locale}
                        />
                      )}
                    </p>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        {!loading && (
          <Modal.Footer>
            <Button
              variant="outline-primary btn-ms-icon"
              onClick={() => setConfirmVerify(false)}
            >
              <i className={"ri-close-line"}></i> <span>{locale("Close")}</span>
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal
        className={"right modalLg"}
        show={showAddCredits}
        onHide={handleCloseBuyCredits}
        backdrop={"static"}
        keyboard={false}
      >
        <BuyMoreCredits
          locale={locale}
          handleClose={handleCloseBuyCredits}
          team_id={currentTeamId}
          currentCompanySetting={currentCompanySetting}
          latestAvailableCredits={latestAvailableCredits}
          manageCredit={null}
        />
      </Modal>
    </React.Fragment>
  );
};

export const ShowButton = ({
  credits,
  handleInitiate,
  setShowAddCredits,
  locale,
}) => {
  if (
    parseInt(credits.available_credits) >=
    (credits.credit_usage > 4
      ? parseInt(credits.credit_usage / 5)
      : credits.credit_usage > 0
      ? 1
      : 0)
  ) {
    return (
      <Button variant="link outline-primary" onClick={handleInitiate}>
        {locale("Validate Records")}
      </Button>
    );
  } else {
    return (
      <Button
        variant="link outline-primary"
        onClick={() => setShowAddCredits(true)}
      >
        {locale("Add Credits")}
      </Button>
    );
  }
};

import { useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router";
import { TrialPlanChecker } from "../../../Context/TrialPlanChecker";

import { ALL_INVENTORY_STATUS } from "../../../GraphQueries/Users/Inventory";
import { currentTeamId } from "../../../Utils/utils";

export const TopNotification = () => {
  const { pathname } = useLocation();
  const { t: locale } = useTranslation();
  const [showTopNotification, setShowTopNotification] = useState(false);
  const [statusFor, setStatusFor] = useState("");
  const [upgradeFor, setUpgradeFor] = useState("addon");
  const [buttonSelection, setButtonSelection] = useState(null);
  const [redirectTo, setRedirectTo] = useState(false);
  const { planData, isPlanExpired } = useContext(TrialPlanChecker);

  const [fetchAllInventoryStatus, { data: allInventoryStatus }] = useLazyQuery(
    ALL_INVENTORY_STATUS,
    { fetchPolicy: "no-cache" }
  );
  const fetchInventoryStatus = () => {
    fetchAllInventoryStatus({
      variables: { team_id: currentTeamId },
      fetchPolicy: "no-cache",
    });
    // setShow(true);
  };

  useEffect(() => {
    if (planData !== null) {
      if (isPlanExpired) {
        //Do Nothing...
      } else {
        setTimeout(() => {
          fetchInventoryStatus();
        }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planData]);

  useEffect(() => {
    if (allInventoryStatus && allInventoryStatus.allInventoryStatus) {
      if (allInventoryStatus.allInventoryStatus.status === true) {
        //console.log(JSON.parse(allInventoryStatus.allInventoryStatus.for_type));
        setStatusFor(
          JSON.parse(allInventoryStatus.allInventoryStatus.for_type).join(", ")
        );
        setShowTopNotification(true);
        setUpgradeFor(
          allInventoryStatus.allInventoryStatus.upgrade_available_for
        );
      }
    }
  }, [allInventoryStatus]);

  const buyAddonPlan = async (value) => {
    await setButtonSelection(value);
    await setRedirectTo(true);
  };

  if (!showTopNotification) return null;
  if (redirectTo && pathname !== "/settings/accounts-and-billing") {
    return (
      <Navigate
        to="/settings/accounts-and-billing"
        state={{
          redirectPageParams: {
            activeTab: "accounts-and-billing",
            subTab: buttonSelection,
          },
        }}
      />
    );
  } else if (redirectTo && pathname === "/settings/accounts-and-billing") {
    setRedirectTo(false);
    setShowTopNotification(false);
  }

  return (
    <div className="bg-light text-dark pt-2 pb-3 pb-sm-2 px-4 top-navigation">
      <Row className="align-items-center">
        <Col>
          <div className="d-flex align-items-center">
            <i className="ri-information-fill me-2"></i>
            <p className="m-0">
              {locale(
                "messages:notification.You have reached the limit for {statusFor}. You can increase inventory by adding addon or upgrading your plan.",
                {
                  statusFor: statusFor,
                }
              )}
            </p>
          </div>
        </Col>
        <Col sm="auto" className="pe-0 ps-5 ps-sm-2">
          {upgradeFor === "addon" && (
            <Button
              className="btn btn-primary btn-outline-primary btn-sm me-1"
              onClick={() => buyAddonPlan("addons")}
            >
              {locale("Buy Addon")}
            </Button>
          )}
          <Button
            className="btn btn-primary btn-outline-primary btn-sm"
            onClick={() => buyAddonPlan("plan")}
          >
            {locale("Upgrade Plan")}
          </Button>
        </Col>
        <Col sm="auto" className="ps-0 top-nav-close">
          <Button
            variant={"link"}
            className="btn-sm top-bar-close px-0 text-black"
            onClick={() => setShowTopNotification(false)}
          >
            <i className="ri-close-line ri-xl ms-2"></i>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

import React, { useContext, useEffect } from "react";

import AppRoutes from "../Routes/Routes";
import { AuthContext } from "../Context/AuthContext";

const Layouts = ({ children }) => {
  const { authUser } = useContext(AuthContext);
  useEffect(() => {
    authUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppRoutes />
    </>
  );
};

export default Layouts;

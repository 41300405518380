import { gql } from "@apollo/client";

/** Product listing **/
export const CHECKOUT = gql`
  mutation checkout(
    $layout: String
    $billing_type: String
    $firstName: String
    $lastName: String
    $email: String
    $company: String
    $job_title: String
    $date_of_birth: Date
    $mobile: String
    $fax: String
    $website: String
    $billing_address: ContactBillingAddress
    $coupon: String
    $planId: String
    $source: String
    $price: Float
    $taxesId: [Int]
    $cardNumber: String
    $month: String
    $year: String
    $cvv: String
    $cardType: String
    $nameOnCard: String
    $password: String
    $locale: String
    $visitor_id: String
    $referer: String
    $trackerData: String
  ) {
    checkoutLayout(
      layout: $layout
      billing_type: $billing_type
      first_name: $firstName
      last_name: $lastName
      email: $email
      company: $company
      job_title: $job_title
      date_of_birth: $date_of_birth
      mobile: $mobile
      fax: $fax
      website: $website
      billing_address: $billing_address
      coupon: $coupon
      plan_id: $planId
      source: $source
      price: $price
      taxes_id: $taxesId
      card_name: $nameOnCard
      card_number: $cardNumber
      month: $month
      year: $year
      cvv: $cvv
      card_type: $cardType
      password: $password
      locale: $locale
      visitor_id: $visitor_id
      referer: $referer
      trackerData: $trackerData
    ) {
      status
      subscription_id
      token
    }
  }
`;
/** Coupon Apply And Validate **/
export const COUPON_APPLIED = gql`
  mutation checkCoupon(
    $layout: String
    $coupon: String
    $email: String
    $planId: Int
    $type: String
  ) {
    couponCheckCode(
      layout: $layout
      coupon: $coupon
      email: $email
      plan_id: $planId
      type: $type
    ) {
      discount_type
      amount
      applies_to
    }
  }
`;

/** GET RENEW SUBSCRIPTION DETAIL **/
export const RENEW_SUBSCRIPTION_DETAIL = gql`
  query getSubscriptionDetailsForRenew($subscription_id: String, $upgrade_to_plan: Int) {
    subscriptionDetailForRenewSubscription(subscription_id: $subscription_id, upgrade_to_plan: $upgrade_to_plan) {
      subscription_id
      plan_id
      price
      billing_type
      plan_name
      fee_name
      fee_value
      fee_charge_on
      first_name
      last_name
      email
      phone
      job_title
      date_of_birth
      mobile
      fax
      website
      #address
      billing_address
      currency
      number_format
      sandbox_mode
      status
      plan_detail {
        name
        price
        billing_type
      }
    }
  }
`;

/** RENEW SUBSCRIPTION **/
export const RENEW_SUBSCRIPTION = gql`
  mutation checkoutLayoutRenewSubscription(
    $subscription_id: String
    $first_name: String
    $last_name: String
    $company: String
    $website: String
    $job_title: String
    $coupon: String
    $nameOnCard: String
    $card_number: String
    $month: String
    $year: String
    $cvv: String
    $card_type: String
    $billing_address: ContactBillingAddress
  ) {
    checkoutLayoutRenewSubscription(
      subscription_id: $subscription_id
      first_name: $first_name
      last_name: $last_name
      company: $company
      website: $website
      job_title: $job_title
      billing_address: $billing_address
      coupon: $coupon
      card_name: $nameOnCard
      card_number: $card_number
      month: $month
      year: $year
      cvv: $cvv
      card_type: $card_type
    ) {
      status
    }
  }
`;

/** GET invoice detail for payment **/
export const TRANSACTION_DETAIL_PAYMENT = gql`
  query transactionDetailForPayment($transactionId: String) {
    transactionDetailForPayment(transaction_id: $transactionId) {
      id
      first_name
      last_name
      email
      address
      billing_address
      document_type
      name
      project_name
      offerings_text_form
      due_date
      status
      total
      paid_amount
      payment_requested
      currency
      #time_zone
      #language
      number_format
      sandbox_mode
      cardDetails {
        id
        name
        card_number
        expiry_date
        default
        card_type
      }
    }
  }
`;

export const ACCEPT_TRANSACTION_PAYMENT = gql`
  mutation acceptTransactionPayment(
    $id: String
    $first_name: String
    $last_name: String
    $card_id: Int
    $card_name: String
    $card_number: String
    $month: String
    $year: String
    $cvv: String
    $card_type: String
    $billing_address: ContactBillingAddress
  ) {
    acceptTransactionPayment(
      id: $id
      first_name: $first_name
      last_name: $last_name
      card_id: $card_id
      billing_address: $billing_address
      card_name: $card_name
      card_number: $card_number
      month: $month
      year: $year
      cvv: $cvv
      card_type: $card_type
    ) {
      status
    }
  }
`;

export const SUBSCRIPTION_INVOICE_DETAIL = gql`
  query getSubscriptionInvoiceDetail($transactionId: String) {
    getSubscriptionInvoiceDetail(transaction_id: $transactionId) {
      id
      first_name
      last_name
      email
      address
      billing_address
      document_type
      name
      project_name
      offerings_text_form
      due_date
      status
      total
      paid_amount
      payment_requested
      currency
      number_format
      sandbox_mode
      cardDetails {
        id
        name
        card_number
        expiry_date
        default
        card_type
      }
      transaction_document_items {
        id
        line_type
        line_type_id
        title
        line_price_type
        line_price
        quantity
        description
        tax_type
        tax_value
        itemable {
          ... on UserSubscription {
            id
            name
            price
            billing_period
          }
        }
        created_at
        updated_at
      }
    }
  }
`;

export const ACCEPT_SUBSCRIPTION_PAYMENT = gql`
  mutation acceptSubscriptionPayment(
    $id: String
    $first_name: String
    $last_name: String
    $billing_address: ContactBillingAddress
    $card_name: String
    $card_id: Int
    $card_number: String
    $month: String
    $year: String
    $cvv: String
    $card_type: String
  ) {
    acceptSubscriptionPayment(
      id: $id
      first_name: $first_name
      last_name: $last_name
      billing_address: $billing_address
      card_name: $card_name
      card_id: $card_id
      card_number: $card_number
      month: $month
      year: $year
      cvv: $cvv
      card_type: $card_type
    ) {
      status
    }
  }
`;

export const RENEW_SUBSCRIPTION_PAYMENT = gql`
  mutation checkoutPaymentFailedSubscription(
    $subscription_id: String
    $first_name: String
    $last_name: String
    $phone: String
    $job_title: String
    $date_of_birth: Date
    $mobile: String
    $fax: String
    $website: String
    $company: String
    $additional: String
    $billing_address: ContactBillingAddress
    $card_name: String
    $card_number: String
    $month: String
    $year: String
    $cvv: String
    $card_type: String
  ) {
    checkoutPaymentFailedSubscription(
      subscription_id: $subscription_id
      first_name: $first_name
      last_name: $last_name
      phone: $phone
      job_title: $job_title
      date_of_birth: $date_of_birth
      mobile: $mobile
      fax: $fax
      website: $website
      company: $company
      additional: $additional
      billing_address: $billing_address
      card_name: $card_name
      card_number: $card_number
      month: $month
      year: $year
      cvv: $cvv
      card_type: $card_type
    ) {
      status
      message
    }
  }
`;

import React, { lazy, useContext, useEffect } from "react";

// import { Switch } from "react-router";
import { Routes, Route } from "react-router-dom";
import ExternalSaasInvoiceCheckout from "../Components/Common/Checkout/ExternalSaasInvoiceCheckout";

import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";

import { DialerApp } from "../Components/Common/Twilio/Twilio";
import { AuthContext } from "../Context/AuthContext";
import { isObjectEmpty, publicRoutesName } from "../Utils/utils";

// import componentLoader from "./componentLoader";
const retryCount = 2;
const Callbacks = lazy(() => import("../Pages/Callbacks/Callbacks"), {
  maxRetries: retryCount,
});
/*** SignIn **/
const SignIn = lazy(() => import("../Pages/Login/SignIn"), {
  maxRetries: retryCount,
});
const LoginWithPin = lazy(() => import("../Pages/Login/LoginWithPin"), {
  maxRetries: retryCount,
});
const ForgotPassword = lazy(() => import("../Pages/Login/ForgotPassword"), {
  maxRetries: retryCount,
});
const ResetPassword = lazy(() => import("../Pages/Login/ResetPassword"), {
  maxRetries: retryCount,
});
const Unsubscribe = lazy(() => import("../Pages/Contact/Unsubscribe"), {
  maxRetries: retryCount,
});
/*****/
const SetPassword = lazy(() => import("../Pages/Signup/SetPassword"), {
  maxRetries: retryCount,
});
const ActivateTeamMember = lazy(() => import("../Pages/Signup/Activate"), {
  maxRetries: retryCount,
});
/***SignUp***/
// const SignUp = lazy(() => import("../Pages/Signup/SignUp"));
const NewSignUp = lazy(() => import("../Pages/Signup/NewSignUp"), {
  maxRetries: retryCount,
});
const LinkedinPopup = lazy(() => import("../Pages/Login/LinkedinPopup"), {
  maxRetries: retryCount,
});
const AccountSetupNew = lazy(() => import("../Pages/Setup/AccountSetupNew"), {
  maxRetries: retryCount,
});
const MicrosoftRedirectScreen = lazy(
  () => import("../Pages/Dashboard/MicrosoftRedirectScreen"),
  {
    maxRetries: retryCount,
  }
);
/***Affiliate***/
const SetAffiliatePassword = lazy(
  () => import("../Pages/Login/SetAffiliatePassword"),
  {
    maxRetries: retryCount,
  }
);
const AffiliateSignIn = lazy(() => import("../Pages/Login/AffiliateSignIn"), {
  maxRetries: retryCount,
});

const AffiliateCrm = lazy(() => import("../Pages/Affiliate/AffiliateCrm"), {
  maxRetries: retryCount,
});

const MarketingMaterial = lazy(
  () => import("../Pages/Affiliate/MarketingMaterial"),
  {
    maxRetries: retryCount,
  }
);
/***SUPER ADMIN ***/
const OwnersList = lazy(() => import("../Pages/SuperAdmin/OwnersList"), {
  maxRetries: retryCount,
});
const CompanyDetailAdmin = lazy(
  () => import("../Pages/SuperAdmin/CompanyDetailAdmin"),
  {
    maxRetries: retryCount,
  }
);
/*****/
const PageNotFound = lazy(() => import("../Pages/PageNotFound/PageNotFound"), {
  maxRetries: retryCount,
});
const ChooseCompany = lazy(
  () => import("../Components/Pages/SwitchCompany/ChooseCompany"),
  {
    maxRetries: retryCount,
  }
);
const MyProfile = lazy(() => import("../Pages/Profile/Profile"), {
  maxRetries: retryCount,
});
const Dashboard = lazy(() => import("../Pages/Dashboard/Dashboard"), {
  maxRetries: retryCount,
});
const NewDashboard = lazy(() => import("../Pages/Dashboard/NewDashboard"), {
  maxRetries: retryCount,
});
const AddNewDashboard = lazy(
  () => import("../Pages/Dashboard/AddNewDashboard"),
  {
    maxRetries: retryCount,
  }
);

const CrmDashboard = lazy(() => import("../Pages/Crm/CrmList"), {
  maxRetries: retryCount,
});
const CrmDetails = lazy(() => import("../Pages/Crm/CrmDetails"), {
  maxRetries: retryCount,
});
const CompanyManagement = lazy(
  () => import("../Pages/CompanyManagement/CompanyManagement"),
  {
    maxRetries: retryCount,
  }
);
const CompanyDetails = lazy(
  () => import("../Pages/CompanyManagement/CompanyDetails"),
  {
    maxRetries: retryCount,
  }
);
/**Ecommerce**/
const Ecommerce = lazy(() => import("../Pages/Ecommerce/Ecommerce"), {
  maxRetries: retryCount,
});

/**Services**/
const Services = lazy(() => import("../Pages/Services/Services"), {
  maxRetries: retryCount,
});

/** SaasAndSubscription **/
const SaasAndSubscription = lazy(
  () => import("../Pages/SaasAndSubscription/SaasAndSubscription"),
  {
    maxRetries: retryCount,
  }
);
const AddNewPlan = lazy(
  () => import("../Pages/SaasAndSubscription/Plan/AddNewPlan"),
  {
    maxRetries: retryCount,
  }
);
const AddLayout = lazy(
  () => import("../Pages/SaasAndSubscription/Layout/AddLayout"),
  {
    maxRetries: retryCount,
  }
);
const PlanPreview = lazy(
  () => import("../Components/Common/Preview/PlanPreview"),
  {
    maxRetries: retryCount,
  }
);
/*** Pipeline ***/
const Pipeline = lazy(() => import("../Pages/Pipeline/Pipeline"), {
  maxRetries: retryCount,
});
/** Campaign **/
const Campaign = lazy(() => import("../Pages/Campaign/Campaign"), {
  maxRetries: retryCount,
});
const CampaignAnalytics = lazy(
  () => import("../Pages/Campaign/CampaignAnalytics"),
  {
    maxRetries: retryCount,
  }
);
const CampaignCompare = lazy(
  () => import("../Pages/Campaign/CampaignCompare"),
  {
    maxRetries: retryCount,
  }
);
const CampaignBuilder = lazy(
  () => import("../Pages/Campaign/CampaignBuilder"),
  {
    maxRetries: retryCount,
  }
);
const CampaignTemplate = lazy(
  () => import("../Pages/Campaign/CampaignTemplate"),
  {
    maxRetries: retryCount,
  }
);

/*** Workflow **/
const Workflow = lazy(() => import("../Pages/Workflow/Workflow"), {
  maxRetries: retryCount,
});
const WorkflowBuilder = lazy(
  () => import("../Pages/Workflow/WorkflowBuilder"),
  {
    maxRetries: retryCount,
  }
);
const WorkflowTemplate = lazy(
  () => import("../Pages/Workflow/WorkflowTemplate"),
  {
    maxRetries: retryCount,
  }
);

/*** OTHERS **/
const Bookings = lazy(() => import("../Pages/Booking/Bookings"), {
  maxRetries: retryCount,
});
const Settings = lazy(() => import("../Pages/Settings/Settings"), {
  maxRetries: retryCount,
});
const TeamMemberDetails = lazy(
  () => import("../Components/Pages/Settings/TeamMember/TeamMemberDetails"),
  {
    maxRetries: retryCount,
  }
);
const EmailBuilder = lazy(
  () => import("../Components/Pages/EmailBuilder/EmailBuilder"),
  {
    maxRetries: retryCount,
  }
);
const LandingPageBuilder = lazy(
  () => import("../Components/Pages/LandingPageBuilder/LandingPageBuilder"),
  {
    maxRetries: retryCount,
  }
);

const Notifications = lazy(() => import("../Pages/Profile/Notifications"), {
  maxRetries: retryCount,
});
/*****/
const ResponsivePreview = lazy(
  () => import("../Components/Common/Preview/ResponsivePreview"),
  {
    maxRetries: retryCount,
  }
);
const XChart = lazy(() => import("../Components/Common/XChart/XChart"), {
  maxRetries: retryCount,
});
const ExternalCheckoutPage = lazy(
  () => import("../Components/Common/Checkout/ExternalCheckoutPage"),
  {
    maxRetries: retryCount,
  }
);
const ExternalInvoiceCheckout = lazy(
  () => import("../Components/Common/Checkout/ExternalInvoiceCheckout"),
  {
    maxRetries: retryCount,
  }
);
// const Dialer = lazy(() =>
//   import("../Pages/Twilio/Dialer")
// );
const SocialLayout = lazy(() => import("../Pages/SocialMedia/SocialLayout"), {
  maxRetries: retryCount,
});
const DuplicateContactsListPage = lazy(
  () => import("../Components/Common/Notification/DuplicateContactsListPage"),
  {
    maxRetries: retryCount,
  }
);
const VerificationContactsListing = lazy(
  () => import("../Components/Common/Modals/VerificationContactsListing"),
  {
    maxRetries: retryCount,
  }
);
const VerifyContactsNotification = lazy(
  () =>
    import(
      "../Components/Common/Notification/TeamNotification/VerifyContactsNotifications"
    ),
  {
    maxRetries: retryCount,
  }
);

const ScheduleAMeeting = lazy(
  () => import("../Components/Pages/UserAvailability/ScheduleAMeeting"),
  {
    maxRetries: retryCount,
  }
);

const CancelMeeting = lazy(
  () => import("../Components/Pages/UserAvailability/CancelMeeting"),
  {
    maxRetries: retryCount,
  }
);

const AcceptInvitation = lazy(
  () => import("../Components/Pages/Appointments/AcceptAppointmentInvitation"),
  {
    maxRetries: retryCount,
  }
);

const EqupForm = lazy(() => import("../Components/Pages/Forms/EqupForm"), {
  maxRetries: retryCount,
});
const ClientDetails = lazy(
  () => import("../Pages/ClientPortal/ClientDetails"),
  {
    maxRetries: retryCount,
  }
);

const AgreementBuilder = lazy(
  () =>
    import("../Pages/AgreementsManagement/AgreementBuilder/AgreementBuilder"),
  {
    maxRetries: retryCount,
  }
);

// () => import("../Pages/EstimateManagement/EstimateBuilder"),
const EstimateBuilder = lazy(
  () => import("../Pages/EstimateManagement/EstimateBuilder/EstimateBuilder"),
  {
    maxRetries: retryCount,
  }
);

// () => import("../Pages/EstimateManagement/EstimateTemplateBuilder"),
const EstimateTemplateBuilder = lazy(
  () =>
    import(
      "../Pages/EstimateManagement/EstimateBuilder/EstimateTemplateBuilder"
    ),
  {
    maxRetries: retryCount,
  }
);

const AgreementSigner = lazy(
  () => import("../Pages/AgreementsManagement/SignaturePortal/AgreementSigner"),
  {
    maxRetries: retryCount,
  }
);

const AgreementReviewPage = lazy(
  () =>
    import(
      "../Pages/AgreementsManagement/AgreementBuilder/AgreementReviewPage"
    ),
  {
    maxRetries: retryCount,
  }
);

const EstimateSigner = lazy(
  () => import("../Pages/EstimateManagement/EstimateSigner"),
  {
    maxRetries: retryCount,
  }
);

const EstimateReviewPage = lazy(
  () => import("../Pages/EstimateManagement/EstimateReviewPage"),
  {
    maxRetries: retryCount,
  }
);

/******/
const AppRoutes = (props) => {
  const { logOut } = useContext(AuthContext);

  /**** LOGOUT ****/
  useEffect(() => {
    let expiredTime = 0;
    if (!isObjectEmpty(localStorage.getItem("_expiredTime"))) {
      expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
    }

    if (expiredTime > 0 && expiredTime < Date.now()) {
      logOutUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** ON TIME OUT LOGOUT THE USER **/
  const logOutUser = async () => {
    const redirectTo =
      publicRoutesName.filter((item) => window.location.href.includes(item))
        .length > 0;
    !redirectTo && (await logOut(redirectTo));
  };

  /********/
  return (
    <Routes>
      {/*** PUBLIC ROUTES ***/}
      <Route
        path="/sign-in"
        element={
          <PublicRoutes
            exact
            path="/sign-in"
            hasNavigation={false}
            hasDefault={true}
            component={(props) => <SignIn {...props} />}
          />
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoutes
            exact
            path="/signup"
            hasNavigation={false}
            component={(props) => <NewSignUp {...props} />}
          />
        }
      />
      <Route
        path="/signup/:emailId"
        element={
          <PublicRoutes
            exact
            path="/signup/:emailId"
            hasNavigation={false}
            component={(props) => <NewSignUp {...props} />}
          />
        }
      />
      <Route
        path="/signup/:emailId/:status"
        element={
          <PublicRoutes
            exact
            path="/signup/:emailId/:status"
            hasNavigation={false}
            component={(props) => <NewSignUp {...props} />}
          />
        }
      />
      <Route
        path="/reset-password"
        element={
          <PublicRoutes
            exact
            path="/reset-password"
            hasNavigation={false}
            hasDefault={true}
            component={(props) => <SetPassword {...props} />}
          />
        }
      />
      <Route
        path="/reset/password"
        element={
          <PublicRoutes
            exact
            path="/reset/password"
            hasNavigation={false}
            component={(props) => <ResetPassword {...props} />}
          />
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PublicRoutes
            exact
            path="/forgot-password"
            hasNavigation={false}
            component={(props) => <ForgotPassword {...props} />}
          />
        }
      />
      <Route
        path="/client-login"
        element={
          <PublicRoutes
            exact
            path="/client-login"
            hasNavigation={false}
            component={(props) => <LoginWithPin {...props} />}
          />
        }
      />
      <Route
        path="/callbacks/:id"
        element={
          <PublicRoutes
            exact
            path="/callbacks/:id"
            hasNavigation={false}
            hasDefault={true}
            component={(props) => <Callbacks {...props} />}
          />
        }
      />
      <Route
        path="/schedule-appointment/:userAvailabilityId/"
        element={
          <PublicRoutes
            exact
            path="/schedule-appointment/:userAvailabilityId/"
            hasNavigation={false}
            hasDefault={true}
            component={(props) => <ScheduleAMeeting {...props} />}
          />
        }
      />
      <Route
        path="/cancel-appointment/:appointmentId/"
        element={
          <PublicRoutes
            exact
            path="/cancel-appointment/:appointmentId/"
            hasNavigation={false}
            hasDefault={true}
            component={(props) => <CancelMeeting {...props} />}
          />
        }
      />
      <Route
        path="/schedule-appointment/:userAvailabilityId/:bookingId"
        element={
          <PublicRoutes
            exact
            path="/schedule-appointment/:userAvailabilityId/:bookingId"
            hasNavigation={false}
            hasDefault={true}
            component={(props) => <ScheduleAMeeting {...props} />}
          />
        }
      />
      <Route
        path="/reschedule-appointment/:userAvailabilityId/:appointmentId"
        element={
          <PublicRoutes
            exact
            path="/reschedule-appointment/:userAvailabilityId/:appointmentId"
            hasNavigation={false}
            hasDefault={true}
            component={(props) => <ScheduleAMeeting {...props} />}
          />
        }
      />
      <Route
        path="/bookings/:id"
        element={
          <PublicRoutes
            exact
            path="/bookings/:id"
            hasNavigation={false}
            hasDefault={true}
            component={(props) => <Bookings {...props} />}
          />
        }
      />
      <Route
        path="/appointment/:appId/:contactId"
        element={
          <PublicRoutes
            exact
            path="/appointment/:appId/:contactId"
            hasNavigation={false}
            hasDefault={true}
            component={(props) => <AcceptInvitation {...props} />}
          />
        }
      />
      <Route
        path="/affiliate/login"
        element={
          <PublicRoutes
            exact
            path="/affiliate/login"
            hasNavigation={false}
            component={(props) => <AffiliateSignIn {...props} />}
          />
        }
      />
      <Route
        path="/affiliate-signup"
        element={
          <PublicRoutes
            exact
            path="/affiliate-signup"
            hasNavigation={false}
            component={(props) => <SetAffiliatePassword {...props} />}
          />
        }
      />
      <Route
        path="/unsubscribe/:contactId/:emailId"
        element={
          <PublicRoutes
            exact
            path="/unsubscribe/:contactId/:emailId"
            hasNavigation={false}
            component={(props) => <Unsubscribe {...props} />}
          />
        }
      />
      <Route
        path="/activate"
        element={
          <PublicRoutes
            exact
            path="/activate"
            hasNavigation={false}
            component={(props) => <ActivateTeamMember {...props} />}
          />
        }
      />
      <Route
        path="/login-linkedin"
        element={
          <PublicRoutes
            exact
            path="/login-linkedin"
            hasNavigation={false}
            component={(props) => <LinkedinPopup {...props} />}
          />
        }
      />
      <Route
        path="/checkout/:id"
        element={
          <PublicRoutes
            exact
            path="/checkout/:id"
            hasNavigation={false}
            component={(props) => <PlanPreview {...props} />}
          />
        }
      />
      <Route
        path="/checkout/:id/:plan_id/:plan_type/:email?"
        element={
          <PublicRoutes
            exact
            path="/checkout/:id/:plan_id/:plan_type/:email?"
            hasNavigation={false}
            component={(props) => <PlanPreview {...props} />}
          />
        }
      />
      <Route
        path="/renew/:id"
        element={
          <PublicRoutes
            exact
            path="/renew/:id"
            hasNavigation={false}
            component={(props) => <ExternalCheckoutPage {...props} />}
          />
        }
      />
      <Route
        path="/renew-subscription/:id"
        element={
          <PublicRoutes
            exact
            path="/renew-subscription/:id"
            hasNavigation={false}
            component={(props) => <ExternalCheckoutPage {...props} />}
          />
        }
      />
      <Route
        path="/invoice/:id"
        element={
          <PublicRoutes
            exact
            path="/invoice/:id"
            hasNavigation={false}
            component={(props) => <ExternalInvoiceCheckout {...props} />}
          />
        }
      />
      <Route
        path="/quote/:id"
        element={
          <PublicRoutes
            exact
            path="/quote/:id"
            hasNavigation={false}
            component={(props) => <ExternalInvoiceCheckout {...props} />}
          />
        }
      />
      <Route
        path="/subscription/:id"
        element={
          <PublicRoutes
            exact
            path="/subscription/:id"
            hasNavigation={false}
            component={(props) => <ExternalSaasInvoiceCheckout {...props} />}
          />
        }
      />
      <Route
        path="/form/:id"
        element={
          <PublicRoutes
            exact
            path="/orm/:id"
            hasNavigation={false}
            component={(props) => <EqupForm {...props} />}
          />
        }
      />

      <Route
        path="/agreement-signing/:id"
        element={
          <PublicRoutes
            hasNavigation={false}
            exact
            path="/agreement-signing/:id"
            hasDefault={true}
            component={(props) => <AgreementSigner {...props} />}
          />
        }
      />
      <Route
        path="/estimate-signing/:id"
        element={
          <PublicRoutes
            hasNavigation={false}
            exact
            path="/estimate-signing/:id"
            hasDefault={true}
            component={(props) => <EstimateSigner {...props} />}
          />
        }
      />
      {/****** PROTECTED ROUTES *****/}
      <Route
        path=""
        element={
          <ProtectedRoutes
            exact
            path=""
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <Dashboard {...props} />}
          />
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoutes
            exact
            path="/"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <Dashboard {...props} />}
          />
        }
      />
      <Route
        path="/choose-company"
        element={
          <ProtectedRoutes
            exact
            path="/choose-company"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <ChooseCompany {...props} />}
          />
        }
      />
      <Route
        path="/account-setup"
        element={
          <ProtectedRoutes
            exact
            path="/account-setup"
            hasNavigation={false}
            hasDefault={true}
            component={(props) => <AccountSetupNew {...props} />}
          />
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoutes
            exact
            path="/dashboard"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <Dashboard {...props} />}
          />
        }
      />
      <Route
        path="/new-dashboard"
        element={
          <ProtectedRoutes
            exact
            path="/new-dashboard"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <NewDashboard {...props} />}
          />
        }
      />
      <Route
        path="/add-new-dashboard"
        element={
          <ProtectedRoutes
            exact
            path="/add-new-dashboard"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <AddNewDashboard {...props} />}
          />
        }
      />
      <Route
        path="/add-dashboard"
        element={
          <ProtectedRoutes
            hasNavigation={true}
            exact
            hasDefault={false}
            component={(props) => <AddNewDashboard {...props} />}
          />
        }
      />
      <Route
        path="/edit-dashboard"
        element={
          <ProtectedRoutes
            hasNavigation={true}
            exact
            path="/edit-dashboard"
            hasDefault={false}
            component={(props) => <AddNewDashboard {...props} />}
          />
        }
      />
      <Route
        path="/notifications"
        element={
          <ProtectedRoutes
            exact
            path="/notifications"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <Notifications {...props} />}
          />
        }
      />
      <Route
        path="/crm"
        element={
          <ProtectedRoutes
            exact
            path="/crm"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <CrmDashboard {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/contact-details"
        element={
          <ProtectedRoutes
            exact
            path="/contact-details"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <CrmDetails {...props} />}
          />
        }
      />
      <Route
        path="/contact-details/:id"
        element={
          <ProtectedRoutes
            exact
            path="/contact-details/:id"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <CrmDetails {...props} />}
          />
        }
      />
      <Route
        path="/task/:id"
        element={
          <ProtectedRoutes
            exact
            path="/task/:id"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <CrmDetails {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/appointment/:id"
        element={
          <ProtectedRoutes
            exact
            path="/appointment/:id"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <CrmDetails {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/agreement/:id/:agId"
        element={
          <ProtectedRoutes
            exact
            path="/agreement/:id/:agId"
            hasDefault={true}
            component={(props) => <CrmDetails {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/company-management"
        element={
          <ProtectedRoutes
            exact
            path="/company-management"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <CompanyManagement {...props} />}
          />
        }
      />
      <Route
        path="/company-details"
        element={
          <ProtectedRoutes
            exact
            path="/company-details"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <CompanyDetails {...props} />}
          />
        }
      />
      <Route
        path="/owners"
        element={
          <ProtectedRoutes
            exact
            path="/owners"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <OwnersList {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/owner-details"
        element={
          <ProtectedRoutes
            exact
            path="/owner-details"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <CompanyDetailAdmin {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/dialer"
        element={
          <ProtectedRoutes
            exact
            path="/dialer"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <DialerApp {...props} />}
          />
        }
      />
      <Route
        path="/affiliate-crm"
        element={
          <ProtectedRoutes
            exact
            path="/affiliate-crm"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <AffiliateCrm {...props} />}
          />
        }
      />
      <Route
        path="/marketing-material"
        element={
          <ProtectedRoutes
            exact
            path="/marketing-material"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <MarketingMaterial {...props} />}
          />
        }
      />
      <Route
        path="/login-microsoft"
        element={
          <ProtectedRoutes
            exact
            path="/login-microsoft"
            hasNavigation={false}
            hasDefault={true}
            component={(props) => <MicrosoftRedirectScreen {...props} />}
          />
        }
      />
      <Route
        path="/pipeline"
        element={
          <ProtectedRoutes
            exact
            path="/pipeline"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Pipeline {...props} />}
          />
        }
      />
      <Route
        path="/deal/:id"
        element={
          <ProtectedRoutes
            exact
            path="/deal/:id"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Pipeline {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/campaign"
        element={
          <ProtectedRoutes
            exact
            path="/campaign"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Campaign {...props} />}
          />
        }
      />
      <Route
        path="/campaign-analytics"
        element={
          <ProtectedRoutes
            exact
            path="/campaign-analytics"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <CampaignAnalytics {...props} />}
          />
        }
      />
      <Route
        path="/campaign-compare"
        element={
          <ProtectedRoutes
            exact
            path="/campaign-compare"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <CampaignCompare {...props} />}
          />
        }
      />
      <Route
        path="/choose-prebuilt-campaigns"
        element={
          <ProtectedRoutes
            exact
            path="/choose-prebuilt-campaigns"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <CampaignTemplate {...props} />}
          />
        }
      />
      <Route
        path="/campaign-builder"
        element={
          <ProtectedRoutes
            exact
            path="/campaign-builder"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <CampaignBuilder {...props} />}
          />
        }
      />
      <Route
        path="/workflow"
        element={
          <ProtectedRoutes
            exact
            path="/workflow"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Workflow {...props} />}
          />
        }
      />
      <Route
        path="/choose-prebuilt-workflows"
        element={
          <ProtectedRoutes
            exact
            path="/choose-prebuilt-workflows"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <WorkflowTemplate {...props} />}
          />
        }
      />
      <Route
        path="/workflow-builder"
        element={
          <ProtectedRoutes
            exact
            path="/workflow-builder"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <WorkflowBuilder {...props} />}
          />
        }
      />

      <Route
        path="/ecommerce"
        element={
          <ProtectedRoutes
            exact
            path="/ecommerce"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Ecommerce {...props} />}
          />
        }
      />
      <Route
        path="/services"
        element={
          <ProtectedRoutes
            exact
            path="/services"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Services {...props} />}
          />
        }
      />
      <Route
        path="/quotes"
        element={
          <ProtectedRoutes
            exact
            path="/quotes"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Services {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/quotes/:id"
        element={
          <ProtectedRoutes
            exact
            path="/quotes/:id"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Services {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/projects"
        element={
          <ProtectedRoutes
            exact
            path="/projects"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Services {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/projects/:id"
        element={
          <ProtectedRoutes
            exact
            path="/projects/:id"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Services {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/subscription"
        element={
          <ProtectedRoutes
            exact
            path="/subscription"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <SaasAndSubscription {...props} />}
          />
        }
      />
      <Route
        path="/add-plan"
        element={
          <ProtectedRoutes
            exact
            path="/add-plan"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <AddNewPlan {...props} />}
          />
        }
      />
      <Route
        path="/add-layout"
        element={
          <ProtectedRoutes
            exact
            path="/add-layout"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <AddLayout {...props} />}
          />
        }
      />
      <Route
        path="/responsive-preview"
        element={
          <ProtectedRoutes
            exact
            path="/responsive-preview"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <ResponsivePreview {...props} />}
          />
        }
      />
      <Route
        path="/social-media/:id"
        element={
          <ProtectedRoutes
            exact
            path="/social-media/:id"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <SocialLayout {...props} />}
          />
        }
      />

      <Route
        path="/settings"
        element={
          <ProtectedRoutes
            exact
            path="/settings"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <Settings {...props} />}
          />
        }
      />

      <Route
        path="/settings/:id"
        element={
          <ProtectedRoutes
            exact
            path="/settings/:id"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Settings {...props} />}
          />
        }
      />
      <Route
        path="/buy-addon"
        element={
          <ProtectedRoutes
            hasNavigation={false}
            exact
            path="/buy-addon"
            hasDefault={false}
            component={(props) => <Settings {...props} />}
          />
        }
      />
      <Route
        path="/buy-plan"
        element={
          <ProtectedRoutes
            hasNavigation={false}
            exact
            path="/buy-plan"
            hasDefault={false}
            component={(props) => <Settings {...props} />}
          />
        }
      />
      <Route
        path="/website-tracking-details"
        element={
          <ProtectedRoutes
            exact
            path="/website-tracking-details"
            hasNavigation={true}
            hasDefault={false}
            component={(props) => <Settings {...props} />}
            {...props}
          />
        }
      />
      <Route
        path="/team-member"
        element={
          <ProtectedRoutes
            hasNavigation={true}
            exact
            path="/team-member"
            hasDefault={true}
            component={(props) => <TeamMemberDetails {...props} />}
          />
        }
      />
      <Route
        path="/my-profile"
        element={
          <ProtectedRoutes
            hasNavigation={true}
            exact
            path="/my-profile"
            hasDefault={true}
            component={(props) => <MyProfile {...props} />}
          />
        }
      />
      <Route
        path="/email-builder"
        element={
          <ProtectedRoutes
            hasNavigation={false}
            exact
            path="/email-builder"
            hasDefault={false}
            component={(props) => <EmailBuilder {...props} />}
          />
        }
      />
      <Route
        path="/test-chart"
        element={
          <ProtectedRoutes
            hasNavigation={true}
            exact
            path="/test-chart"
            hasDefault={false}
            component={(props) => <XChart {...props} />}
          />
        }
      />
      <Route
        path="/landing-page-builder"
        element={
          <ProtectedRoutes
            hasNavigation={false}
            exact
            path="/landing-page-builder"
            hasDefault={false}
            component={(props) => <LandingPageBuilder {...props} />}
          />
        }
      />
      <Route
        path="/contacts-verification"
        element={
          <ProtectedRoutes
            exact
            path="/contacts-verification"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <VerificationContactsListing {...props} />}
          />
        }
      />
      <Route
        path="/email-verification-notification"
        element={
          <ProtectedRoutes
            exact
            path="/email-verification-notification"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <VerifyContactsNotification {...props} />}
          />
        }
      />
      <Route
        path="/duplicate-contacts"
        element={
          <ProtectedRoutes
            exact
            path="/duplicate-contacts"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <DuplicateContactsListPage {...props} />}
          />
        }
      />

      <Route
        path="/client-dashboard"
        element={
          <ProtectedRoutes
            exact
            path="/client-dashboard"
            hasNavigation={true}
            hasDefault={true}
            component={(props) => <ClientDetails {...props} />}
          />
        }
      />
      <Route
        path="/agreement-builder"
        element={
          <ProtectedRoutes
            hasNavigation={false}
            exact
            path="/agreement-builder"
            hasDefault={true}
            component={(props) => <AgreementBuilder {...props} />}
          />
        }
      />

      <Route
        path="/agreement-review/:id"
        element={
          <ProtectedRoutes
            hasNavigation={true}
            exact
            path="/agreement-review/:id"
            hasDefault={true}
            component={(props) => <AgreementReviewPage {...props} />}
          />
        }
      />
      <Route
        path="/estimate-builder"
        element={
          <ProtectedRoutes
            hasNavigation={false}
            exact
            path="/estimate-builder"
            hasDefault={true}
            component={(props) => <EstimateBuilder {...props} />}
          />
        }
      />

      <Route
        path="/estimate-template-builder"
        element={
          <ProtectedRoutes
            hasNavigation={false}
            exact
            path="/estimate-template-builder"
            hasDefault={true}
            component={(props) => <EstimateTemplateBuilder {...props} />}
          />
        }
      />

      <Route
        path="/estimate-review/:id"
        element={
          <ProtectedRoutes
            hasNavigation={true}
            exact
            path="/estimate-review/:id"
            hasDefault={true}
            component={(props) => <EstimateReviewPage {...props} />}
          />
        }
      />
      {/** DEFAULT ROUTES FOR PAGE NOT FOUND **/}
      <Route
        path="*"
        hasNavigation={true}
        hasDefault={true}
        element={<ProtectedRoutes path="*" component={PageNotFound} />}
      />
    </Routes>
  );
};

export default AppRoutes;

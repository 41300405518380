import moment from "moment";
import { handleTimezoneLocale } from "../i18n";
import { currencies } from "./CurrencyListWithSymbols";
import { getLanguage } from "./utils";

const timeZone = require("moment-timezone");
export const defaultCurrency = process.env.REACT_APP_DEFAULT_CURRENCY;
export const defaultDateFormat = process.env.REACT_APP_DEFAULT_DATE_FORMAT;
export const defaultNumberFormat = process.env.REACT_APP_DEFAULT_NUMBER_FORMAT;
export const defaultPhoneNumber =
  process.env.REACT_APP_DEFAULT_PHONE_NUMBER_FORMAT;
export const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
export const defaultTimeZone = process.env.REACT_APP_DEFAULT_TIME_ZONE;

export const getSettings = (settings) => {
  let settingObject = {};
  settings.length > 0 &&
    settings.forEach((item) => {
      if (item) {
        settingObject = {
          ...settingObject,
          [item.setting_name]: item.setting_value,
        };
      }
    });
  return settingObject;
};

/***RETURN USER DATE FORMAT ACCORDING TO USER TIMEZONE***/
export const getDateByUserFormat = (
  settings,
  value = "",
  defaultValue = ""
) => {
  if (value !== "" && value !== null && value !== undefined) {
    /** Set Date Format Variable **/
    const dateFormat =
      settings &&
      settings.date_format &&
      settings.date_format !== "" &&
      settings.date_format !== null
        ? settings.date_format
        : defaultDateFormat;

    /**** SET USER LANGUAGE ****/
    if (settings && settings.language) {
      let language = "en";
      if (settings.language) {
        language = getLanguage({ language: settings.language });
      }
      handleTimezoneLocale(language);
      if (moment.locale() !== language) {
        moment.locale(language);
      }
    } else {
      let language = "en";
      handleTimezoneLocale(language);
      if (moment.locale() !== language) {
        moment.locale(language);
      }
    }
    /***********************/
    const convertedDate = getDateByUserTimeZone(settings, value, defaultValue);
    return moment.utc(convertedDate).format(dateFormat);
  } else {
    return defaultValue;
  }
};
/***RETURN DATE TIME FORMAT ACCORDING TO USER TIMEZONE***/
export const getDateByUserTimeZone = (
  settings,
  value = "",
  defaultValue = "",
  format12hour = false
) => {
  if (value !== "" && value !== null && value !== undefined) {
    /** Set Date Format Variable **/
    const dateFormat = format12hour
      ? "YYYY-MM-DD hh:mm A"
      : "YYYY-MM-DD HH:mm:ss";
    /** Set Timezone Format Variable **/
    const userTimeZone =
      settings &&
      settings.time_zone &&
      settings.time_zone !== "" &&
      settings.time_zone !== null
        ? settings.time_zone
        : defaultTimeZone;

    /**** SET USER LANGUAGE ****/
    if (settings && settings.language) {
      let language = "en";
      if (settings.language) {
        language = getLanguage({ language: settings.language });
      }
      handleTimezoneLocale(language);
      if (moment.locale() !== language) {
        moment.locale(language);
      }
    }
    /******/
    // const utcDate = moment(value).utc(true).format();
    const isoFormats = {
      am: "YYYY-MM-DD HH:mm a",
      pm: "YYYY-MM-DD HH:mm a",
      AM: "YYYY-MM-DD HH:mm A",
      PM: "YYYY-MM-DD HH:mm A",
    };
    value = moment(value, "YYYY-MM-DD HH:mm a").utc(true).format("YYYY-MM-DD HH:mm A");
    const formatKeys = Object.keys(isoFormats);
    let selectedFormat = formatKeys.filter((item) => value.includes(item));
    const utcDate = moment(value, isoFormats[selectedFormat]).utc(true);
    const convertedDate = timeZone(utcDate).tz(userTimeZone);
    return convertedDate.format(dateFormat);
  } else {
    return defaultValue;
  }
};

/*** RETURN ONLY DATE FORMAT ACCORDING TO USER TIMEZONE ***/
export const getDateFormatOnly = (settings, value = "", defaultValue = "") => {
  if (value !== "" && value !== null && value !== undefined) {
    /** Set Date Format Variable **/
    let dateFormat =
      settings &&
      settings.date_format &&
      settings.date_format !== "" &&
      settings.date_format !== null
        ? settings.date_format
        : defaultDateFormat;
    dateFormat = dateFormat.replace(" h:mm:ss A", "");

    if (dateFormat.lastIndexOf(",") > -1) {
      dateFormat = dateFormat.slice(0, dateFormat.length - 1);
    }
    const convertedDate = getDateByUserTimeZone(settings, value, defaultValue);
    /**** SET USER LANGUAGE ****/

    let language = "en";
    if (settings && settings.language) {
      if (settings.language) {
        language = getLanguage({ language: settings.language });
      }
    }

    handleTimezoneLocale(language);
    if (moment.locale() !== language) {
      moment.locale(language);
    }

    /*****/
    return moment.utc(convertedDate).format(dateFormat);
  } else {
    return defaultValue;
  }
};

export const getNumberByUserFormat = (
  settings,
  value = "",
  type = "number",
  defaultValue = ""
) => {
  value = parseFloat(value);

  if (value !== "" && value !== null && value !== undefined && !isNaN(value)) {
    const numberFormat =
      settings && settings.number_format
        ? settings.number_format
        : defaultNumberFormat;

    if (type === "currency") {
      if (numberFormat === "1,00,000") {
        return (
          getCurrencyByUserFormat(settings) +
          value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
        );
      } else if (numberFormat === "1 00 000") {
        return (
          getCurrencyByUserFormat(settings) +
          value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&/ ")
        );
      } else if (numberFormat === "100000") {
        return getCurrencyByUserFormat(settings) + value.toFixed(2);
      } else {
        return getCurrencyByUserFormat(settings) + value.toFixed(2);
      }
    } else {
      if (numberFormat === "1,00,000") {
        const val = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
        const myVal = val.split(".");
        return myVal[1] > 0 ? val : myVal[0];
      } else if (numberFormat === "1 00 000") {
        const val = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$& ");
        const myVal = val.split(".");
        return myVal[1] > 0 ? val : myVal[0];
      } else if (numberFormat === "100000") {
        return value;
      } else {
        return value;
      }
    }
  } else {
    return defaultValue;
  }
};

/**
 *
 * @param {*} settings
 * @param {*} value
 * @param {*} defaultValue
 * @returns formatted phone/mobile number in user defined format...
 */
export const getPhoneByUserFormat = (
  settings,
  value = "",
  defaultValue = ""
) => {
  if (value !== "" && value !== null && value !== undefined) {
    const userPhoneFormat =
      settings && settings.phone_number_format && settings.phone_number_format
        ? settings.phone_number_format
        : defaultPhoneNumber;

    if (userPhoneFormat === "(123) 234-5689" && false) {
      // const match = value.match(/(\d{3})(\d{3})(\d{4})$/);
      let myRegex = /^(\d{0,4})(\d{3})(\d{3})(\d{4,7})$/;
      value = value.replace(/\D/g, "");
      const match = value.match(myRegex);

      if (match) {
        return (
          "+" + match[1] + " (" + match[2] + ") " + match[3] + "-" + match[4]
        );
      }
    } else if (userPhoneFormat === "123-4567890" && false) {
      value = value.replace(/\D/g, "");
      // const match = value.match(/(\d{3})(\d{7})$/);
      const match = value.match(/^(\d{0,4})(\d{3})(\d{7})$/);
      if (match) {
        return "+" + match[1] + " " + match[2] + "-" + match[3];
      }
    } else if (userPhoneFormat === "1234567890") {
      return value;
    } else {
      return value;
    }
  } else {
    return defaultValue;
  }
};

export const getCurrencyByUserFormat = (settings) => {
  let currency = defaultCurrency;
  if (settings && settings.currency) {
    currency = settings.currency;
  }
  return (
    currencies &&
    currencies.filter(
      (item) => item.code.toLowerCase() === currency.toLowerCase()
    )[0].symbol
  );
};

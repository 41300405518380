import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { pagesEN, commonEN, messagesEN } from "./locales/en/index";
import { pagesPT, commonPT, messagesPT } from "./locales/pt/index";
import { pagesFR, commonFR, messagesFR } from "./locales/fr/index";
import { pagesES, commonES, messagesES } from "./locales/es/index";
// Make sure moment.js has the required locale data
import "moment/locale/pt";
import "moment/locale/fr";
import "moment/locale/es";
import moment from "moment";

const resources = {
  en: {
    common: commonEN,
    pages: pagesEN,
    messages: messagesEN,
  },
  pt: {
    common: commonPT,
    pages: pagesPT,
    messages: messagesPT,
  },
  fr: {
    common: commonFR,
    pages: pagesFR,
    messages: messagesFR,
  },
  es: {
    common: commonES,
    pages: pagesES,
    messages: messagesES,
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  debug: false,
  resources,
  ns: ["common", "pages"],
  defaultNS: "common",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export const handleTimezoneLocale = (userLanguage) => {
  // Make sure moment.js has the required locale data
  // import "moment/locale/en-nz";
  // import "moment/locale/pt";
  //"en-nz"
  const language =
    userLanguage === "english" || userLanguage === "en" ? "en" : userLanguage;
  if (language !== i18n.language) {
    i18n.changeLanguage(language);
  }
  if (moment.locale() !== language) {
    moment.locale(language);
  }
};
export default i18n;

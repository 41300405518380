import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router";

const UpgradePlan = ({ message = null }) => {
  const { pathname } = useLocation();
  const { t: locale } = useTranslation(["common", "pages"]);
  const [redirectTo, setRedirectTo] = useState(false);
  const [buttonSelection, setButtonSelection] = useState(false);

  const upgradePlan = async (value) => {
    await setButtonSelection(value);
    await setRedirectTo(true);
  };

  if (redirectTo && pathname !== "/settings/accounts-and-billing") {
    return (
      <Navigate
        to="/settings/accounts-and-billing"
        state={{
          redirectPageParams: {
            activeTab: "accounts-and-billing",
            subTab: buttonSelection,
          },
        }}
      />
    );
  } else if (redirectTo && pathname === "/settings/accounts-and-billing") {
    setRedirectTo(false);
  }

  return (
    <Container>
      <div
        className="border p-4"
        style={{ textAlign: "center", margin: "100px auto" }}
      >
        <h3>
          {message !== null ? message : locale("You do not have permission.")}
        </h3>
        <h5 className="mb-4">
          {locale("Upgrade your plan to access this feature.")}
        </h5>
        <Button variant="outline-primary " onClick={() => upgradePlan("plan")}>
          {locale("Upgrade Plan")}
        </Button>
      </div>
    </Container>
  );
};

export default UpgradePlan;

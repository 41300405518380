import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import axios from 'axios'; // To get CSRF token
import Cookies from 'js-cookie'; // To read cookies easily
import Pusher from "pusher-js";
import PusherLink from "./PusherLink";
import { API_URL } from "../Utils/utils";

// Cached CSRF token
let csrfTokenLoaded = false;

// Function to fetch CSRF token before making requests
const getCsrfToken = async () => {
  if (!csrfTokenLoaded) { // Only fetch if not already loaded
    try {
      await axios.get(`${API_URL}/sanctum/csrf-cookie`, {
        withCredentials: true, // Ensures the CSRF token is stored in cookies
      });
      csrfTokenLoaded = true; // Mark token as loaded
    } catch (error) {
      console.error('Failed to load CSRF token:', error);
      csrfTokenLoaded = false;
    }
  }
};

// Function to reset CSRF token when needed
const resetCsrfToken = async () => {
  csrfTokenLoaded = false; // Mark token as not loaded
  await getCsrfToken(); // Re-fetch CSRF token
};

// Create the upload link for handling GraphQL uploads with credentials
const uploadLink = createUploadLink({
  uri: API_URL + "/graphql",
  credentials: 'include', // Passes the cookies for session-based authentication
});

// Set up the authLink, responsible for ensuring the CSRF token is loaded before requests
const authLink = setContext(async (_, { headers }) => {
  // Fetch CSRF token if it's not loaded yet
  await getCsrfToken();

  // Read the XSRF-TOKEN from cookies
  const xsrfToken = Cookies.get('XSRF-TOKEN');

  // Return headers, including the CSRF token in the X-XSRF-TOKEN header
  return {
    headers: {
      ...headers,
      'X-XSRF-TOKEN': xsrfToken ? decodeURIComponent(xsrfToken) : '',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest', // Add this line for the X-Requested-With header
    },
  };
});

// Set up an error link to handle CSRF expiration
const errorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    // Check if the response has a 'page expired' error (HTTP status code 419)
    if (response.errors) {
      const expiredError = response.errors.find(
        (error) => error.extensions?.response?.status === 419
      );

      if (expiredError) {
        // Token is expired, reset the CSRF token for the next request
        resetCsrfToken();
      }
    }
    return response;
  });
});

// Set up the Pusher link (without bearer tokens since CSRF token is used)
const pusherLink = new PusherLink({
  pusher: new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    authEndpoint: `${process.env.REACT_APP_BACKEND_URL}/graphql/subscriptions/auth`,
    auth: {
      headers: {}, // No need for Bearer tokens, we use CSRF cookies for session-based auth
    },
  }),
});

// Combine the links: errorLink -> authLink -> pusherLink -> uploadLink
const link = ApolloLink.from([errorLink, authLink, pusherLink, uploadLink]);

// Create the Apollo Client instance
const graphqlClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

export default graphqlClient;

/***Country List and States List***/
export const getCountriesList = (countries) => {
  return countries.country.map(
    (country) =>
      country && {
        value: country.id,
        label: country.name,
        countryCode: country.code,
        phoneCode: country.phone_code,
      }
  );
};

export const getStateList = (countries) => {
  return countries.state.map(
    (state) =>
      state && {
        value: state.id,
        label: state.name,
        countryCode: state.country_code,
        countryId: state.country_id,
      }
  );
};

export const getCountryWiseStates = (countries, countryId) => {
  let stateList = [];
  countries.state.forEach((state) => {
    if (state && parseInt(countryId) === parseInt(state.country_id)) {
      stateList.push({
        value: state.id,
        label: state.name,
        countryCode: state.country_id,
      });
    }
  });
  return stateList;
};

export const getCountryByName = (countries, countryName) => {
  const countryByName = countries.filter(
    (country) => country && countryName === country.label
  );
  return countryByName.length > 0 ? countryByName[0] : "";
};

export const getCountryByCode = (countries, countryCode) => {
  const countryByName = countries.filter(
    (country) =>
      country &&
      (countryCode === country.countryCode || countryCode === country.code)
  );
  return countryByName.length > 0 ? countryByName[0] : "";
};

export const getStateByName = (states, stateName) => {
  let stateByName = "";
  states.state.forEach((state) => {
    if (state && stateName === state.name) {
      stateByName = {
        value: state.id,
        label: state.name,
        countryCode: state.country_id,
      };
    }
  });
  return stateByName;
};

export const countryWiseLanguage = {
  //Portugese
  Brazil: "pt",
  Angola: "pt",
  "Cape Verde": "pt",
  "East Timor": "pt",
  "Guinea-Bissau": "pt",
  Macau: "pt",
  Mozambique: "pt",
  Portugal: "pt",
  "Sao Tome & Principe": "pt",
  //Spanish
  Argentina: "es",
  Bolivia: "es",
  Chile: "es",
  Colombia: "es",
  "Costa Rica": "es",
  Cuba: "es",
  "Dominican Republic": "es",
  Ecuador: "es",
  "El Salvador": "es",
  Guatemala: "es",
  Honduras: "es",
  Mexico: "es",
  Nicaragua: "es",
  Panama: "es",
  Paraguay: "es",
  Peru: "es",
  Uruguay: "es",
  Venezuela: "es",
  "Puerto Rico": "es",
  Spain: "es",
  "Equatorial Guinea": "es",
  //French
  Belgium: "fr",
  Benin: "fr",
  "Burkina Faso": "fr",
  Burundi: "fr",
  Cameroon: "fr",
  "Central African Rep.": "fr",
  "Central African Republic": "fr",
  Chad: "fr",
  Comoros: "fr",
  "Côte d'Ivoire": "fr",
  "Congo (Rep.)": "fr",
  "Congo (Dem. Rep.)": "fr",
  Djibouti: "fr",
  // Equatorial: "fr",
  France: "fr",
  Guinea: "fr",
  Haiti: "fr",
  Luxembourg: "fr",
  Madagascar: "fr",
  Mali: "fr",
  Monaco: "fr",
  Niger: "fr",
};

import { gql } from "@apollo/client";

export const DEAL_STAGES = gql`
  query getStages($module: String, $team_id: Int) {
    stages(category: $module, team_id: $team_id) {
      id
      team_id
      name
      label
      category
      probability
      created_at
      updated_at
    }
  }
`;
/** Deal listing **/
export const DEAL_LIST = gql`
  query dealSearchFilter(
    $filter: [CommonFilter!]
    $search: String
    $field: String!
    $order: SortOrder!
    $contactId: Int
    $first: Int!
    $page: Int
    $team_id: Int
    $pipelineId: Int
  ) {
    dealSearchFilter(
      team_id: $team_id
      filter: $filter
      search: $search
      contact_id: $contactId
      orderBy: [{ column: $field, order: $order }]
      pipeline_id: $pipelineId
      first: $first
      page: $page
    ) {
      data {
        id
        deal_id
        team_id
        name
        quote {
          id
          name
        }
        booking {
          id
        }
        owner {
          id
          name
        }
        contact {
          id
          name
          company
          company_id
          deleted_at
          image {
            path: url
          }
          enable_portal
        }
        stage {
          id
          name
          probability
        }
        amount
        custom_attributes {
          id
          name
          value
        }
        created_at
        updated_at
        associated_products {
          id
          image
          name
        }
        stage_created_date
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;
/** Add DEAL **/
export const DEAL_CREATE = gql`
  mutation createDeal(
    $name: String
    $contactId: Int
    $ownerId: [Int]
    $stageId: Int
    $dealProducts: [Int]
    $additional: String
    $dealType: String
    $amount: Float
    $quote: Int
    $team_id: Int
  ) {
    createDeal(
      team_id: $team_id
      name: $name
      contact_id: $contactId
      owner_id: $ownerId
      stage_id: $stageId
      deal_products: $dealProducts
      additional: $additional
      deal_type: $dealType
      amount: $amount
      quote_id: $quote
    ) {
      id
      team_id
      name
      quote {
        id
        name
      }
      booking {
        id
      }
      owner {
        id
        name
      }
      contact {
        id
        name
        company
        company_id
        deleted_at
        image {
          path: url
        }
      }
      stage {
        id
        name
        probability
      }
      amount
      custom_attributes {
        id
        name
        value
      }
      created_at
      updated_at
      associated_products {
        id
        image
        name
      }
      stage_created_date
    }
  }
`;

/** REMOVE DEAL **/
export const DEAL_REMOVE = gql`
  mutation deleteDeal($id: Int, $team_id: Int) {
    deleteDeal(id: $id, team_id: $team_id) {
      status
    }
  }
`;
/** DEAL Update **/
export const DEAL_UPDATE = gql`
  mutation updateDeal(
    $id: Int
    $name: String
    $contactId: Int
    $ownerId: [Int]
    $stageId: Int
    $dealProducts: [Int]
    $removeDealProducts: [Int]
    $additional: String
    $dealType: String
    $amount: Float
    $team_id: Int
  ) {
    updateDeal(
      id: $id
      name: $name
      contact_id: $contactId
      owner_id: $ownerId
      stage_id: $stageId
      deal_products: $dealProducts
      remove_deal_products: $removeDealProducts
      additional: $additional
      deal_type: $dealType
      amount: $amount
      team_id: $team_id
    ) {
      id
      team_id
      name
      quote {
        id
        name
      }
      booking {
        id
      }
      owner {
        id
        name
      }
      contact {
        id
        name
        company
        company_id
        deleted_at
        image {
          path: url
        }
      }
      stage {
        id
        name
        probability
      }
      amount
      custom_attributes {
        id
        name
        value
      }
      created_at
      updated_at
      associated_products {
        id
        image
        name
      }
      stage_created_date
    }
  }
`;
/** DEAL BY ID **/
export const DEAL_BY_ID = gql`
  query dealDetail($id: ID, $team_id: Int) {
    dealDetail(id: $id, team_id: $team_id) {
      id
      team_id
      name
      deal_id
      quote {
        id
        team_id
        document_type
        owner
        payment_mode
        payment_url
        contact {
          id
          name
          company
          email
          phone
          company_id
          deleted_at
          image {
            path: url
          }
        }
        document_type

        name
        offerings_text_form
        due_date
        status
        quote_status
        project_send_status
        total
        amount_paid_percent
        paid_amount
        payment_requested
        payment_mode
        created_at
        canceled_date
        transaction_document_items {
          id
          line_type
          line_type_id
          title
          line_price_type
          line_price
          quantity
          description
          tax_type
          tax_value

          itemable {
            ... on Product {
              name
              price
              tax_applicable
            }
            ... on Plan {
              name
              price
            }
            ... on Coupon {
              name
              price
            }
          }

          created_at
          updated_at
        }
        created_at
        updated_at
      }
      booking {
        id
      }
      owner {
        id
        name
      }
      contact {
        id
        name
        company
        company_id
        deleted_at
        image {
          path: url
        }
      }
      stage {
        id
        name
        probability
      }
      amount
      custom_attributes {
        id
        name
        value
      }
      created_at
      updated_at
      associated_products {
        id
        image
        name
      }
      stage_created_date
    }
  }
`;

/** DEAL AND STAGES AND QUOTES LIST **/
export const DEAL_AND_STAGES = gql`
  query getStages($withoutDeal: Boolean, $team_id: Int, $pipelineId: Int) {
    getAllTransactionDocuments(without_deal: $withoutDeal, team_id: $team_id) {
      id
      name
      total
      status
      #converted_to_project
      canceled_date
      contact {
        id
        name
        company
        company_id
        deleted_at
        image {
          path: url
        }
      }
    }

    dealAnalytics(team_id: $team_id, pipeline_id: $pipelineId) {
      win_ratio
      avg_cycle_time
      avg_deal_size
      all_deals_count
      all_deals_amount
      won_deals_count
      won_deals_amount
      lost_deals_count
      lost_deals_amount
    }
  }
`;
/* Update Deal Label */
export const STAGE_LABEL_UPDATE = gql`
  mutation updateStageLabel($id: Int, $label: String, $team_id: Int) {
    updateStage(id: $id, label: $label, team_id: $team_id) {
      id
    }
  }
`;

/** DEAL AND STAGES AND QUOTES LIST **/
export const DEAL_STAGES_AND_OWNER = gql`
  query getStages($leadOwner: Boolean!, $team_id: Int) {
    #stages(category: $module, team_id: $team_id) {
    #  id
    #  team_id
    #  name
    #  label
    #  category
    #  probability
    #  created_at
    #  updated_at
    #}
    #pipelinesList(team_id: $team_id) {
    #id
    #name
    #default_pipeline
    #created_at
    #updated_at
    #stages {
    #id
    #team_id
    #name
    #label
    #category
    #probability
    #created_at
    #updated_at
    #sort_order
    #}
    #}
    allUsers(team_id: $team_id) @include(if: $leadOwner) {
      id
      name
    }
  }
`;

/** All Deals By a Company with pagination **/
//NOTE: HERE "CONTACT ID" REPRESENT THE "COMPANY ID"
export const DEALS_BY_COMPANY = gql`
  query companyDeals(
    $team_id: Int
    $contactId: Int
    $first: Int!
    $page: Int
    $search: String
    #$field: String!
    #$order: SortOrder!
    $module: String
    $pipelineId: Int
  ) {
    dealSearchFilterPaginate(
      team_id: $team_id
      first: $first
      page: $page
      search: $search
      #orderBy: [{ column: $field, order: $order }]
      company_id: $contactId
      pipeline_id: $pipelineId
    ) {
      data {
        id
        team_id
        name
        quote {
          id
          name
        }
        booking {
          id
        }
        owner {
          id
          name
        }
        contact {
          id
          name
          company
          company_id
          deleted_at
          deleted_at
          image {
            path: url
          }
        }
        stage {
          id
          name
          probability
        }
        amount
        custom_attributes {
          id
          name
          value
        }
        created_at
        updated_at
        associated_products {
          id
          image
          name
        }
        stage_created_date
      }
      paginatorInfo {
        currentPage
        total
        lastPage
      }
    }
    stages(category: $module, team_id: $team_id) {
      id
      team_id
      name
      label
      category
      probability
      created_at
      updated_at
    }
  }
`;

/** PIPELINE AND STAGES LIST **/
export const PIPELINES = gql`
  query pipelinesList($team_id: Int) {
    pipelinesList(team_id: $team_id) {
      id
      name
      default_pipeline
      created_at
      updated_at
      pipeline_id
      stages {
        id
        team_id
        name
        label
        category
        probability
        created_at
        updated_at
        sort_order
        stage_id
      }
    }
  }
`;

/** CREATE PIPELINE **/
export const CREATE_PIPELINES = gql`
  mutation createPipeline(
    $team_id: Int
    $name: String
    $stage: [StageInput!]!
  ) {
    createPipeline(team_id: $team_id, name: $name, stage: $stage) {
      id
      name
      created_at
      updated_at
      default_pipeline
      stages {
        id
        team_id
        name
        label
        category
        probability
        created_at
        updated_at
        sort_order
      }
    }
  }
`;
/** UPDATE PIPELINE **/
export const UPDATE_PIPELINES = gql`
  mutation updatePipeline(
    $id: Int
    $team_id: Int
    $name: String
    $existing_stages: [EditStageInput!]!
    $stage: [StageInput]
    $remove_stages: String
  ) {
    updatePipeline(
      id: $id
      team_id: $team_id
      name: $name
      new_stages: $stage
      remove_stages: $remove_stages
      existing_stages: $existing_stages
    ) {
      id
      name
      created_at
      updated_at
      default_pipeline
      stages {
        id
        team_id
        name
        label
        category
        probability
        created_at
        updated_at
        sort_order
      }
    }
  }
`;
/** UPDATE PIPELINE **/
export const DELETE_PIPELINE = gql`
  mutation deletePipeline($id: Int, $team_id: Int) {
    deletePipeline(id: $id, team_id: $team_id) {
      status
    }
  }
`;

export const PIPELINE_ANALYTICS = gql`
  query dealAnalytics($team_id: Int, $pipeline_id: Int) {
    dealAnalytics(team_id: $team_id, pipeline_id: $pipeline_id) {
      win_ratio
      avg_cycle_time
      avg_deal_size
      all_deals_count
      all_deals_amount
      won_deals_count
      won_deals_amount
      lost_deals_count
      lost_deals_amount
    }
  }
`;

export const CONTACT_DEALS_AND_STAGES = gql`
  query getStages(
    $filter: [CommonFilter!]
    $search: String
    $field: String!
    $order: SortOrder!
    $contactId: Int
    $first: Int!
    $page: Int
    $withoutDeal: Boolean
    $team_id: Int
    $pipelineId: Int
  ) {
    getAllTransactionDocuments(without_deal: $withoutDeal, team_id: $team_id) {
      id
      name
      total
      status
      #converted_to_project
      canceled_date
      contact {
        id
        name
        company
        company_id
        deleted_at
        image {
          path: url
        }
      }
    }

    dealAnalytics(team_id: $team_id, pipeline_id: $pipelineId) {
      win_ratio
      avg_cycle_time
      avg_deal_size
      all_deals_count
      all_deals_amount
      won_deals_count
      won_deals_amount
      lost_deals_count
      lost_deals_amount
    }

    dealSearchFilter(
      team_id: $team_id
      filter: $filter
      search: $search
      contact_id: $contactId
      orderBy: [{ column: $field, order: $order }]
      pipeline_id: $pipelineId
      first: $first
      page: $page
    ) {
      data {
        id
        deal_id
        team_id
        name
        quote {
          id
          name
        }
        booking {
          id
        }
        owner {
          id
          name
        }
        contact {
          id
          name
          company
          company_id
          deleted_at
          image {
            path: url
          }
        }
        stage {
          id
          name
          probability
        }
        amount
        custom_attributes {
          id
          name
          value
        }
        created_at
        updated_at
        associated_products {
          id
          image
          name
        }
        stage_created_date
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;

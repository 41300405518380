import React, { useContext, useReducer } from "react";
import { currentTeamId } from "../Utils/utils";
import {
  FETCH_DUPLICATE_ENTRIES,
  REMOVE_DUPLICATES,
} from "../GraphQueries/Contacts/DuplicateContacts";
import { useLazyQuery, useMutation } from "@apollo/client";
import { TrialPlanChecker } from "./TrialPlanChecker";

const initialState = {
  isFetching: false,
  contacts: null,
  duplicatesCount: 0,
  isContactRemoved: false,
  error: null,
};

export const DuplicateContactsContext = React.createContext();

function DuplicateContactReducer(state, action) {
  switch (action.type) {
    case "GET_DUPLICATE_REQUEST":
      return { ...state, isFetching: true };
    case "REMOVE_DUPLICATE_REQUEST":
      return { ...state, isFetching: true };
    case "REMOVE_DUPLICATE_SUCCESS":
      return { ...state, isContactRemoved: true };
    case "DUPLICATE_SUCCESS":
      return {
        ...state,
        isFetching: false,
        contacts: action.payload,
        duplicatesCount:
          action.payload && action.payload.length > 0
            ? action.payload.reduce(
                (total, currentValue, currentIndex, arr) => {
                  return (
                    total +
                    (currentValue.contacts.length > 1
                      ? currentValue.contacts.length
                      : 0)
                  );
                },
                0
              )
            : 0,
        error: null,
        isContactRemoved: false,
      };
    case "DUPLICATE_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

// AuthContext.displayName = "Auth";

const DuplicateContactsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    DuplicateContactReducer,
    initialState,
    undefined
  );
  const { planData, isPlanExpired } = useContext(TrialPlanChecker);

  const [fetchDuplicateEntries] = useLazyQuery(FETCH_DUPLICATE_ENTRIES, {
    fetchPolicy: "no-cache",
  });
  const [removedDuplicates] = useMutation(REMOVE_DUPLICATES);

  // reload duplicate entries after set interval...
  const loadDuplicateEntries = () => {
    if (planData !== null) {
      if (isPlanExpired) {
        return false;
      } else {
        fetchDuplicateEntries({
          variables: {
            team_id: currentTeamId,
          },
          fetchPolicy: "no-cache",
        })
          .then((result) => {
            if (result.data && !result.error) {
              const contactsData = result.data.fetchDuplicateEntries.filter(
                (entry) => entry?.contacts?.length > 1
              );
              dispatch({
                type: "DUPLICATE_SUCCESS",
                payload: contactsData,
              });
            }
          })
          .catch((error) => {
            console.log("error:", error);
            let { graphQLErrors } = error;
            dispatch({ type: "DUPLICATE_FAILURE", payload: graphQLErrors });
          });
      }
    }
  };

  //send remove request to backend to remove user selected duplicate contact...
  const removeContact = (contactId, duplicateId) => {
    removedDuplicates({
      variables: {
        teamId: currentTeamId,
        contactId: parseInt(contactId),
        duplicateId: parseInt(duplicateId),
      },
    })
      .then((result) => {
        dispatch({ type: "REMOVE_DUPLICATE_SUCCESS" });
        value.getDuplicateData();
        // deleteRemovedContacts(contactId);
        // handleAlertMessage("success", "Remove contact successfully");
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        console.log("error:", error);
        dispatch({ type: "DUPLICATE_FAILURE", payload: graphQLErrors });
      });
  };
  /*******/
  const value = {
    duplicateData: {
      isFetching: state.isFetching,
      contacts: state.contacts,
      duplicatesCount: state.duplicatesCount,
      isContactRemoved: state.isContactRemoved,
      error: state.error,
    },

    getDuplicateData: () => {
      dispatch({ type: "GET_DUPLICATE_REQUEST" });

      loadDuplicateEntries();
    },

    setDuplicateData: (data) => {
      dispatch({ type: "DUPLICATE_SUCCESS", payload: data });
    },

    removeDuplicateData: (contactId, duplicateId) => {
      dispatch({ type: "GET_DUPLICATE_REQUEST" });
      removeContact(contactId, duplicateId);
    },
  };

  return (
    <DuplicateContactsContext.Provider value={value}>
      {children}
    </DuplicateContactsContext.Provider>
  );
};

export default DuplicateContactsContextProvider;

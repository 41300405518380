import React, { useReducer, useEffect } from "react";
/* Retrieve Data From Server */
import { useLazyQuery } from "@apollo/client";
import { PIPELINES } from "../GraphQueries/DealList";
import { currentTeamId, publicRoutesName } from "../Utils/utils";

const initialState = {
  isFetching: false,
  pipelineData: null,
  error: null,
  // authenticated: false,
};

const actions = {
  GET_PIPELINE_REQUEST: "GET_PIPELINE_REQUEST",
  GET_PIPELINE_SUCCESS: "GET_PIPELINE_SUCCESS",
  GET_PIPELINE_FAILURE: "GET_PIPELINE_FAILURE",
  SET_PIPELINE: "SET_PIPELINE",
};

function pipelineReducer(state, action) {
  switch (action.type) {
    case actions.GET_PIPELINE_REQUEST:
      return { ...state, isFetching: true };
    case actions.GET_PIPELINE_SUCCESS:
      return { ...state, isFetching: false, pipelineData: action.payload };
    case actions.GET_PIPELINE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    case actions.SET_PIPELINE:
      return { ...state, pipelineData: action.payload };
    default:
      return state;
  }
}
export const PipelineContext = React.createContext();

function PipelineContextProvider({ children }) {
  /** GET ALL PIPELINE LIST **/
  const [pipelinesList, { data: pipelinesListData, error: pipelineError }] =
    useLazyQuery(PIPELINES, {
      fetchPolicy: "no-cache",
    });

  const [state, dispatch] = useReducer(
    pipelineReducer,
    initialState,
    undefined
  );

  // useEffect(() => {
  //   if (
  //     (state && !state.isFetching && state.pipelineData === null) ||
  //     (state.pipelineData && state.pipelineData.pipelines === null)
  //   ) {
  //      value.getPipelines();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state, currentTeamId]);

  /*** GET DATA FROM QUERY AND SET STATE ***/
  useEffect(() => {
    if (pipelinesListData) {
      dispatch({
        type: actions.GET_PIPELINE_SUCCESS,
        payload: pipelinesListData.pipelinesList,
      });
    }
    if (pipelineError) {
      console.log("ERROR::", pipelineError);
      dispatch({ type: "GET_PIPELINE_FAILURE", payload: pipelineError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelinesListData, pipelineError]);

  const value = {
    /*** PIPELINE DATA STATE ***/
    pipelineData: {
      isFetching: state.isFetching,
      pipelines: state.pipelineData,
      error: state.error,
    },

    /*** CALL QUERY AND GET ALL PIPELINES WITH STAGES ***/
    getPipelines: () => {
      if (
        publicRoutesName.filter(
          (item) => window.location.pathname.indexOf(item) > -1
        ).length === 0
      ) {
        if (!isNaN(currentTeamId) && value.pipelineData.error === null) {
          dispatch({ type: actions.GET_PIPELINE_REQUEST });
          try {
            pipelinesList({
              variables: { team_id: currentTeamId },
            });
          } catch (error) {
            console.log("HerE::", error);
            dispatch({ type: "GET_PIPELINE_FAILURE", payload: error.response });
          }
        }
      }
    },

    /*** SET PIPELINE DATA TO CONTEXT ***/
    setPipelineDetail: (pipelines, type) => {
      const previousPipelineData = value.pipelineData.pipelines.map((item) => {
        if (parseInt(item.id) === parseInt(pipelines.id) && type === "create") {
          return {
            ...item,
            created_at: pipelines.created_at,
            default_pipeline: pipelines.default_pipeline,
            id: pipelines.id,
            label: pipelines.label,
            name: pipelines.name,
            stages: [pipelines.stages],
            updated_at: pipelines.updated_at,
          };
        } else {
          return item;
        }
      });
      if (type === "create") {
        previousPipelineData.push({
          created_at: pipelines.created_at,
          default_pipeline: pipelines.default_pipeline,
          id: pipelines.id,
          label: pipelines.label,
          name: pipelines.name,
          stages: [pipelines.stages],
          updated_at: pipelines.updated_at,
          value: pipelines.id,
        });
      }

      dispatch({
        type: actions.SET_PIPELINE,
        payload: [...previousPipelineData],
      });
    },

    setPipelineIsFetching: () => {
      dispatch({ type: actions.GET_PIPELINE_SUCCESS, payload: null });
    },
  };

  return (
    <PipelineContext.Provider value={value}>
      {children}
    </PipelineContext.Provider>
  );
}

export default PipelineContextProvider;

/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import {
  getDateByUserTimeZone,
  getDateFormatOnly,
  getNumberByUserFormat,
} from './CurrentSetting';
import { Badge } from 'react-bootstrap';

import Session from './Session';
import { handleTimezoneLocale } from '../i18n';
// import { createBrowserHistory } from "history";
import { debounce } from 'lodash';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { countryWiseLanguage } from './Countries';
import { createBrowserHistory } from '@remix-run/router';
import { useLocation } from 'react-router';
import equpLogoSvg from '../Images/equp-logo.svg';

const timeZone = require('moment-timezone');
export const userAvatar = require('../Images/user-avtar.png');
export const defaultImage = require('../Images/image-default-2.gif');
export const ENVIRONMENT = process.env.REACT_APP_ENV;

export const APP_URL = process.env.REACT_APP_URL;
export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;

export const API_URL = process.env.REACT_APP_BACKEND_URL;

export const defaultPageCount = process.env.REACT_APP_DEFAULT_PAGES_COUNT
  ? process.env.REACT_APP_DEFAULT_PAGES_COUNT
  : 100;

export const defaultFormCount = process.env.REACT_APP_DEFAULT_FORMS_COUNT
  ? process.env.REACT_APP_DEFAULT_FORMS_COUNT
  : 10;
export const socialRedirectUrl = process.env.REACT_APP_SOCIAL_REDIRECT_URL;

export const fbAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
export const fbAppSecret = process.env.REACT_APP_FB_SECRET;
export const equpLogo = equpLogoSvg;
export const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/gi;
export const hashTagRegex = /((#[A-Za-z\d-][^\s]+))/gi;
export const hashTagRegexIN = /((#[A-Za-z\d-}][^\s]+))/gi;

export const PUSHER_KEY = process.env.REACT_APP_PUSHER_API_KEY
  ? process.env.REACT_APP_PUSHER_API_KEY
  : null;

export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER
  ? process.env.REACT_APP_PUSHER_CLUSTER
  : null;

export const getTermsAnPolicy = (locale) => {
  return locale('pages:signUp.Accept the EQUP {tos} and {toc}.', {
    tos:
      '<a href=' +
      WEBSITE_URL +
      '/terms-condition target="_blank" rel="noreferrer">' +
      locale('pages:signUp.Terms of Service') +
      '</a>',
    toc:
      '<a href=' +
      WEBSITE_URL +
      '/privacy-policy target="_blank" rel="noreferrer" >' +
      locale('pages:signUp.Privacy Policy') +
      '</a>',
  });
};

export const getCookie = (cname) => {
  return Session.getSessionVar(cname);
};

export const apiToken = getCookie('idToken');
export const defaultPhoneNumberFormat = '(123) 234-5689';

export const fetchCurrentTeamId = () => {
  if (['', undefined, null].indexOf(getCookie('currentTeam')) === -1) {
    return parseInt(getCookie('currentTeam'));
  } else {
    try {
      return parseInt(window.localStorage.getItem('currentTeam'));
    } catch (e) {
      return null;
    }
  }
};

export let currentTeamId = fetchCurrentTeamId();

export const getCurrentTeamId = (teamId = null) => {
  if (!isObjectEmpty(teamId)) {
    currentTeamId = parseInt(teamId);
  } else if (teamId === null && !isNaN(parseInt(getCookie('currentTeam')))) {
    currentTeamId = parseInt(getCookie('currentTeam'));
  } else {
    try {
      if (!isNaN(parseInt(window.localStorage.getItem('currentTeam')))) {
        currentTeamId = parseInt(window.localStorage.getItem('currentTeam'));
      } else {
        currentTeamId = null;
      }
    } catch (e) {
      currentTeamId = null;
    }
  }
};

export const getLocaleFromCookie = () => {
  if (Session.getSessionVar('locale')) {
    handleTimezoneLocale(Session.getSessionVar('locale'));
  } else {
    handleTimezoneLocale('en');
  }
};
export const getDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
};

export const getTime = (timestamp) => {
  const date = new Date(timestamp);
  return date.getHours() + ':' + date.getMinutes();
};

export const uniqueUser = () => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 25; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

export const clientDateTime = () => {
  const d = new Date();
  return d.toUTCString();
};

export const toSnackCase = (str) => {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('_')
  );
};
export const toCamelCase = (str) => {
  const sentence = str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join(' ')
    .split(' ');

  for (var i = 1; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join('');
};
export const toHyphenCase = (str) => {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-')
  );
};

export const toTitleCase = (str) => {
  const sentence = str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join(' ')
    .split(' ');

  //const sentence = str.toLowerCase().replace(/_/g, " ").split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join('');
};

export const toWordCase = (str) => {
  if (str && str.length > 0) {
    try {
      const sentence = str
        .match(
          /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map((x) => x.toLowerCase())
        .join(' ')
        .split(' ');

      //const sentence = str.toLowerCase().replace(/_/g, " ").split(" ");
      for (var i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }

      return sentence.join(' ');
    } catch (e) {
      return str;
    }
  } else {
    return str;
  }
};

/***JSON TO HTML CONVERTOR***/

let styleArray = [];
export const htmlToJsonMain = (html) => {
  styleArray = [];
  htmlToJson(html);
  return styleArray;
};

export const htmlToJson = (html) => {
  let jsonObj = [];
  const text =
    html.childNodes[0] && html.childNodes[0].nodeName === 'text'
      ? html.textContent
      : '';
  const attr = getAttribute(html);
  jsonObj.push({
    tagName: html.tagName,
    text: text,
    attribute: attr,
    children: getChild(html),
  });
  styleArray.push(attr);
  return jsonObj;
};

export const getAttribute = (node) => {
  let attribute = [];
  let attrs = node.attributes;
  if (attrs && attrs.length > 0) {
    for (let i = 0; i < attrs.length; i++) {
      attribute.push({ [attrs[i].name]: attrs[i].value });
    }
  }

  return attribute;
};

export const getChild = (node) => {
  let children = [];
  if (node.hasChildNodes()) {
    node.childNodes.forEach((child, idx) => {
      children.push(htmlToJson(child));
    });
  }
  return children;
};

/** Recursive Function **/
export const addIdAttribute = (htmlObj, index = 0, timestamp) => {
  if (htmlObj.hasChildNodes()) {
    htmlObj.childNodes.forEach((child, idx) => {
      if (child.nodeName !== '#text') {
        child.setAttribute(
          'id',
          'feature-list-' + timestamp + '-' + index + '-' + idx
        );
        child.classList.add('ed-elem-text');
        if (child.hasChildNodes()) {
          addIdAttribute(child, index + '-' + idx, timestamp);
        }
      }
    });
  }
  return htmlObj;
};

/*** Download File Functionality ***/

export const downloadFile = (url, name, mimeType = null) => {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      console.log('BLOB::', blob);
      const url =
        mimeType !== null
          ? window.URL.createObjectURL(blob)
          : window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((e) => {
      console.log('Error::', e.message);
      downloadFileViaLink(url, name);
    });
};

export const downloadFileFromContent = (
  content,
  filename,
  mimeType = 'application/octet-stream'
) => {
  // Create a new Blob object from the content.
  const blob = new Blob([content], { type: mimeType });
  // Create a new anchor element with the download attribute set to the filename.
  const anchor = document.createElement('a');
  anchor.href = window.URL.createObjectURL(blob);
  anchor.download = filename;
  // Append the anchor element to the document body and click it.
  document.body.appendChild(anchor);
  anchor.click();
  // Remove the anchor element from the document body.
  document.body.removeChild(anchor);
};

export const downloadFileViaLink = (url, name) => {
  var link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.target = '_new';
  link.dispatchEvent(new MouseEvent('click'));
};

/*** Get USer Industry ***/
export const getUserIndustry = (userData) => {
  const allIndustries = {
    ecommerce: 'ecommerce',
    services: 'service',
    subscriptions: 'saas',
  };

  if (
    userData &&
    userData.authData &&
    userData.authData.currentTeam &&
    userData.authData.currentTeam.pivot &&
    userData.authData.currentTeam.pivot.industry_info
  ) {
    const industries = JSON.parse(
      userData.authData.currentTeam.pivot.industry_info
    );
    const industry = Object.keys(industries).filter(
      (item) => industries[item] && item
    );
    return industry.length > 0 ? allIndustries[industry[0]] : '';
  } else if (
    userData &&
    userData.authData &&
    !isObjectEmpty(userData.authData.industry_info)
  ) {
    const industries = JSON.parse(userData.authData.industry_info);
    const industry = Object.keys(industries).filter(
      (item) => industries[item] && item
    );
    return industry.length > 0 ? allIndustries[industry[0]] : '';
  } else if (
    userData &&
    userData.authData &&
    userData.authData.currentTeam &&
    !isObjectEmpty(userData.authData.currentTeam.industry_info)
  ) {
    const industries = JSON.parse(userData.authData.currentTeam.industry_info);
    const industry = Object.keys(industries).filter(
      (item) => industries[item] && item
    );
    return industry.length > 0 ? allIndustries[industry[0]] : '';
  } else {
    return '';
  }
};

export const formatUrl = (url) => {
  if (url === undefined || url === null || url === '') {
    return url;
  }
  if (!/^https?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  return url;
};

/** Get Validation message **/
export const getValidationMessage = (graphQLErrors) => {
  if (
    graphQLErrors &&
    graphQLErrors.length &&
    graphQLErrors[0].extensions.category === 'validation'
  ) {
    for (const [key, value] of Object.entries(
      graphQLErrors[0].extensions.validation
    )) {
      return value;
    }
  } else if (
    graphQLErrors &&
    graphQLErrors.length &&
    graphQLErrors[0].extensions.category === 'custom'
  ) {
    console.log('REASON:', graphQLErrors[0].extensions.reason);
    return graphQLErrors[0].extensions.reason;
  } else if (
    graphQLErrors &&
    graphQLErrors.length &&
    graphQLErrors[0].extensions.category === 'graphql'
  ) {
    return 'something went wrong';
  } else if (
    graphQLErrors &&
    graphQLErrors.length &&
    graphQLErrors[0].extensions.category === 'internal'
  ) {
    return graphQLErrors[0].message;
  }
};

export const lastUpdated = (timeStamp) => {
  var startDate = new Date(timeStamp);
  // Do your operations
  var endDate = new Date();
  var res = (endDate.getTime() - startDate.getTime()) / 1000;
  // //get total Months between two dates
  var year = Math.floor(res / (86400 * 365));
  // //get total Months between two dates
  var months = Math.floor(res / (86400 * 30));
  // //get total days between two dates
  var days = Math.floor(res / 86400);
  // // get hours
  var hours = Math.floor(res / 3600);
  // // get minutes
  var minutes = Math.floor(res / 60);
  if (year > 0) {
    return year + (year > 1 ? ' Years' : ' Year') + ' ago';
  } else if (months > 0) {
    return months + (months > 1 ? ' Months' : ' Month') + ' ago';
  } else if (days > 0) {
    return days + (days > 1 ? ' Days' : ' Day') + ' ago';
  } else if (hours > 0) {
    return hours + (hours > 1 ? ' Hours' : ' Hour') + ' ago';
  } else if (minutes > 0) {
    return minutes + (minutes > 1 ? ' Minutes' : ' Minute') + ' ago';
  } else {
    return 'Just now';
  }
};

// convert seconds to hours, minutes and seconds...
export const convertToHumanReadableSeconds = (time) => {
  // console.log("time: ", time);
  const hours = Math.floor(time / 3600);
  time = time - hours * 3600;
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  let timeString = '';
  if (parseInt(hours) > 0) {
    timeString += parseInt(hours) + ' hr ';
  }
  if (parseInt(minutes) > 0) {
    timeString += parseInt(minutes) + ' min ';
  }
  if (parseInt(seconds) > 0) {
    timeString += parseInt(seconds) + ' sec ';
  }
  return timeString;
};

export const getDateTimeForSocialPost = (
  created_at,
  setting,
  channel = null,
  onlyDate = false
) => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const newDateFormat = 'YYYY-MM-DD HH:mm a';
  const newTwitterDateFormat = 'ddd MMM DD hh:mm:ss ZZ YYYY';
  if (['instagram', 'facebook'].indexOf(channel) > -1) {
    // 2022-08-09T07:38:47+0000
    created_at = moment(created_at, 'YYYY-MM-DDTHH:mm:ss+ZZ').format(
      dateFormat
    );
  } else if (channel === 'twitter') {
    //Fri Aug 12 08:47:22 +0000 2022
    created_at = moment(created_at, newTwitterDateFormat).format(dateFormat);
  } else if (channel === 'linkedin') {
    //1663747556197
    created_at = moment.unix(created_at / 1000).format(dateFormat);
  } else if (channel === 'dateOnly') {
    created_at = moment(created_at, 'MM-DD-YYYY').format(dateFormat);
  } else if (created_at) {
    created_at = moment(created_at, newDateFormat).format(dateFormat);
  }

  moment.tz.setDefault(setting.time_zone ? setting.time_zone : 'utc');
  const language = getLanguage(setting);
  handleTimezoneLocale(language);
  if (onlyDate) {
    return moment(created_at).format('DD MMM YY');
  } else {
    return moment(created_at).format('DD MMM YY [at] HH:mm A');
  }
};

export const getTimeAgo = (created_at, setting, channel = null) => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const newDateFormat = 'YYYY-MM-DD HH:mm a';
  const newTwitterDateFormat = 'ddd MMM DD hh:mm:ss ZZ YYYY';
  if (['instagram', 'facebook'].indexOf(channel) > -1) {
    // 2022-08-09T07:38:47+0000
    created_at = moment(created_at, 'YYYY-MM-DDTHH:mm:ss+ZZ').format(
      dateFormat
    );
  } else if (channel === 'twitter') {
    //Fri Aug 12 08:47:22 +0000 2022
    created_at = moment(created_at, newTwitterDateFormat).format(dateFormat);
  } else if (created_at) {
    created_at = moment(created_at, newDateFormat).format(dateFormat);
  }
  moment.tz.setDefault('utc');
  const language = getLanguage(setting);
  handleTimezoneLocale(language);
  return moment(created_at).fromNow();
};

export const compareObjects = (firstObj, secObj) => {
  const firstObjArray = Object.keys(firstObj);
  const secondObjArray = Object.keys(secObj);
  if (firstObjArray.length === secondObjArray.length) {
    const isValueMatch = firstObjArray.filter(
      (item) => firstObj[item] !== secObj[item] && item
    );
    return isValueMatch.length ? false : true;
  } else {
    return false;
  }
};

/** This Function is used To group any kind of Array of Object and Return Grouped Array **/
/**
 *      PARAMS
 *
 * Array: Array of Object.
 * Key: GroupBy key value.
 *
 **/
export const groupBy = (array, key, currentCompanySetting = {}) => {
  let data = null;
  if (['due_date', 'activity_date'].indexOf(key) > -1) {
    data = array.reduce((acc, obj) => {
      const dateTimeString = getDateByUserTimeZone(
        currentCompanySetting,
        obj[key]
      );

      const dateTime = getDateFormatOnly(currentCompanySetting, obj[key]);
      //const dateTime = dateTimeString.split(" ");
      let objectKey = dateTime;
      const property = objectKey;
      acc[property] = acc[property] || [];
      acc[property].push({ ...obj, [key]: dateTimeString });
      return acc;
    }, {});
  } else {
    data = array.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  }
  return Object.keys(data).map(
    (item) => item && { label: item, option: data[item] }
  );
};

export const dateTimeFormat = (dateTime) => {
  var d = new Date(dateTime);
  var n = d.toLocaleString();
  return n;
};

export const convertDateToSeconds = (dateTime) => {
  if (dateTime) {
    var d = new Date(dateTime);
    var n = d.getTime();
    return n;
  } else {
    return 0;
  }
};

/*** Convert Date time in Desired Time Zone ***/
export const getDateViaTimeZone = (dateTime, zone = 330) => {
  if (dateTime) {
    const dateInUtc = moment.parseZone(dateTime).utc().format();
    const dateInDesiredZone = moment
      .parseZone(dateInUtc)
      .utcOffset(zone)
      .format('MMM Do, YYYY h:mm:ss A');
    return dateInDesiredZone;
  } else {
    return 0;
  }
};

export const getSupportedActionFromJson = (jsonString, locale) => {
  /* Convert Supported Operators String to JSON Object */
  let stringArray = jsonString
    .replace('[', '')
    .replace(']', '')
    .replace(/"/g, '');
  stringArray = stringArray.length >= 2 ? stringArray.split(',') : [];
  let itemArray = [];
  stringArray.forEach((item) => {
    itemArray.push({
      value: item.trim(),
      label: locale(toWordCase(item).toLowerCase()),
    });
  });
  return itemArray;
};
/** Mapping Fields for import **/
export const jsonToArray = (jsonValue) => {
  let arrayValue = [
    {
      label: 'Select',
      value: 'default',
    },
  ];
  let formFields = [];
  let defaultArray = [];
  let allPredefineValue = [];
  jsonValue.forEach((item) => {
    if (item.modules.indexOf('imports') !== -1) {
      arrayValue.push({
        label: item.label,
        value: item.name,
        sorting: item.sorting,
        group: item.group,
      });
    }
    if (item.modules.indexOf('form') !== -1) {
      formFields.push({
        label: item.label,
        value: item.name,
        sorting: item.sorting,
        group: item.group,
        type: item.type ? item.type : 'text',
        modules: item.modules,
      });
    }
    if (item.listing_default) {
      defaultArray.push({
        label: item.label,
        name: item.name,
        sorting: item.sorting,
        group: item.group,
      });
    }
    allPredefineValue.push(item);
  });
  return {
    default: defaultArray,
    predefined: allPredefineValue,
    mapping: arrayValue,
    form: formFields,
  };
};

export const getTimeSpent = (time, type = 'seconds') => {
  const timeSpentInSeconds = type === 'seconds' ? time : '';

  const minutes = timeConversion(timeSpentInSeconds, 'seconds');
  const hours = timeConversion(minutes, 'minutes');

  const reminderSeconds = parseInt(timeSpentInSeconds % 60);
  const reminderMin = parseInt(minutes % 60);

  let timeString = '';
  //FOR HOURS PRINT
  if (hours > 0) {
    timeString = timeString.concat(hours, ' Hrs');
    if (reminderMin > 0) {
      timeString = timeString.concat(' ' + reminderMin, ' Min');
    }
    if (reminderSeconds > 0) {
      timeString = timeString.concat(' ' + reminderSeconds, ' Sec');
    }
  }

  //FOR MINUTES PRINT
  else if (minutes > 0) {
    timeString = timeString.concat(minutes, ' Min');
    if (reminderSeconds > 0) {
      timeString = timeString.concat(' ' + reminderSeconds, ' Sec');
    }
  }

  //FOR SECONDS PRINT
  else {
    timeString = timeString.concat(timeSpentInSeconds, ' Sec');
  }
  return timeString;
};

export const timeConversion = (time, type) => {
  if (type === 'seconds') {
    return time >= 60 ? parseInt(time / 60) : 0;
  }
  if (type === 'minutes') {
    return time >= 60 ? parseInt(time / 60) : 0;
  }
};

export function timeConvert(t) {
  let hours = Math.floor(t / 3600);
  let hh = hours < 10 ? '0' + hours.toString() : hours.toString();
  let min = Math.floor((t % 3600) / 60);
  let mm = min < 10 ? '0' + min.toString() : min.toString();
  let sec = (t % 3600) % 60;
  let ss = sec < 10 ? '0' + sec.toString() : sec.toString();
  let ans = hh + ':' + mm + ':' + ss;
  return ans;
}

/***Scroll On Alert message appear ***/
export const scrollToTop = (refObject, element = null) => {
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  } else if (refObject && refObject.current) {
    refObject.current.parentElement.scrollTop = 0;
    refObject.current.parentElement.scrollLeft = 0;
    refObject.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

/***Scroll On Alert message appear ***/
export const scrollToBottom = (refObject, element = null) => {
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  } else if (refObject && refObject.current) {
    refObject.current.scrollIntoView({
      alignToTop: false,
      behavior: 'smooth',
      block: 'end',
    });
  }
};

export const detectCardType = (number) => {
  let cardType = 'unknown';
  var re = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro:
      /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390|6799)\d+$/,
    dankort: /^(5019)\d+$/,
    interpayment: /^(636)\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    jcb: /^(?:2131|1800|30\d{3}|35\d{3})\d{11}$/,
  };

  for (var key in re) {
    if (re[key].test(number)) {
      cardType = key;
    }
  }
  return cardType;
};

// get first character from string...
export const getFirstCharacter = (str, lgth = 1) => {
  return str.replace(/[^a-zA-Z ]/g, '').trim().length > lgth - 1
    ? str
        .replace(/[^a-zA-Z ]/g, '')
        .trim()
        .substring(0, lgth)
        .toUpperCase()
    : ' ';
};

/** Convert Address From Address Object**/
export const getAddress = (address, prefix = '') => {
  let myAddress = [];
  if (address) {
    if (address[prefix + 'address'])
      myAddress.push(address[prefix + 'address']);
    if (address[prefix + 'city']) myAddress.push(address[prefix + 'city']);
    if (address[prefix + 'state']) myAddress.push(address[prefix + 'state']);
    if (address[prefix + 'zip']) {
      myAddress.push(address[prefix + 'zip']);
    } else if (address.post_code) {
      myAddress.push(address.post_code);
    }
    if (address[prefix + 'country'])
      myAddress.push(address[prefix + 'country']);
  }
  return myAddress !== null && myAddress !== undefined
    ? myAddress.join(', ')
    : '';
};

export const cardTypes = {
  visa: require('../Images/card-icon/visa-card.png'),
  mastercard: require('../Images/card-icon/master-card.png'),
  electron: require('../Images/card-icon/electron-card.png'),
  maestro: require('../Images/card-icon/maestro-card.png'),
  dankort: require('../Images/card-icon/dankort-card.png'),
  interpayment: require('../Images/card-icon/interpayment-card.png'),
  unionpay: require('../Images/card-icon/unionpay-card.png'),
  amex: require('../Images/card-icon/amex-card.png'),
  diners: require('../Images/card-icon/diners-card.png'),
  discover: require('../Images/card-icon/discover-card.png'),
  jcb: require('../Images/card-icon/jcb-card.png'),
  unknown: require('../Images/card-icon/dummy-card.png'),
};

const preFilledData = [
  'company_name',
  'phone_number_format',
  'number_format',
  'date_format',
  'time_zone',
  'language',
  'currency',
  'company_address',
  'company_website',
  'company_logo',
];

export const fillNonExistedSettings = (settings) => {
  const data = preFilledData.map((col) => {
    const d = settings.filter((setting) => {
      return setting.setting_name === col;
    });
    if (d.length > 0) {
      return d[0];
    } else {
      let val;
      switch (col) {
        case 'phone_number_format':
          val = defaultPhoneNumberFormat;
          break;
        case 'number_format':
          val = process.env.REACT_APP_DEFAULT_NUMBER_FORMAT;
          break;
        case 'date_format':
          val = process.env.REACT_APP_DEFAULT_DATE_FORMAT;
          break;
        case 'currency':
          val =
            process.env.REACT_APP_DEFAULT_CURRENCY === 'dollar'
              ? '$'
              : process.env.REACT_APP_DEFAULT_CURRENCY;
          break;
        case 'time_zone':
          val = moment.tz.guess();
          break;
        case 'language':
          val = process.env.REACT_APP_DEFAULT_LANGUAGE;
          break;
        default:
          val = '';
      }
      return { setting_name: col, setting_value: val };
    }
  });
  return data;
};

export const equpStatus = (
  value,
  locale,
  textOnly = false,
  userData = null
) => {
  const allStatus = {
    completed: 'completed',
    past_due: 'past-due',
    cancelled: 'cancelled',
    cancel: 'cancelled',
    pending: 'pending',
    upcoming: 'upcoming',
    lead: 'lead',
    contact: 'upcoming',
    customer: 'completed',
    prospect: 'past-due',
    active: 'completed',
    inactive: 'inactive',
    in_active: 'inactive',
    saved: 'upcoming',
    sent: 'past-due',
    paid: 'completed',
    full_paid: 'completed',
    partial_paid: 'completed',
    failed: 'cancelled',
    in_use: 'completed',
    yes: 'completed',
    no: 'cancelled',
    valid: 'completed',
    invalid: 'cancelled',
    disposable: 'cancelled',
    unknown: 'past-due',
    catchall: 'past-due',
    processing: 'upcoming',
    processed: 'completed',
    accept_all: 'past-due',
    delivered: 'completed',
    bounce: 'cancelled',
    opened: 'completed',
    clicked: 'completed',
    approved: 'completed',
    in_review: 'pending',
    rejected: 'cancelled',
    dismiss: 'cancelled',
    paused: 'cancelled',
    draft: 'upcoming',
    read: 'completed',
    undelivered: 'cancelled',
    initiated: 'upcoming',
    accepted: 'completed',
    declined: 'cancelled',
    signed: 'completed',

    under_team_review: 'past-due',
    approver_requested_update: 'cancelled',
    approved_by_approver: 'completed',
    under_client_review: 'past-due',
    under_client_approval: 'past-due',
    approved_by_client: 'completed',
    viewed_by_client: 'past-due',
    partially_completed: 'past-due',
    completed_by_client: 'completed',
    rejected_by_client: 'cancelled',
    ...(userData && hasClient(userData)
      ? {
          sent_for_signature: 'cancelled',
          client_requested_update: 'upcoming',
        }
      : {
          sent_for_signature: 'past-due',
          client_requested_update: 'cancelled',
          viewed: 'past-due',
        }),
  };

  if (value) {
    let lowerCaseValue =
      toSnackCase(value) === 'catchall' ? 'accept_all' : toSnackCase(value);
    let statusValue = allStatus[lowerCaseValue] ? lowerCaseValue : 'invalid';
    const className = toHyphenCase(allStatus[statusValue]);
    if (userData && hasClient(userData)) {
      if (value === 'under_client_review') {
        statusValue = 'need_your_review';
      } else if (value === 'under_client_approval') {
        statusValue = 'need_your_approval';
      } else if (value === 'client_requested_update') {
        statusValue = 'update_requested';
      } else if (value === 'approved_by_client') {
        statusValue = 'approved';
      } else if (value === 'sent_for_signature') {
        statusValue = 'pending_signature';
      } else if (value === 'completed_by_client') {
        statusValue = 'completed';
      } else if (value === 'rejected_by_client') {
        statusValue = 'rejected_by_you';
      } else if (value === 'viewed_by_client') {
        statusValue = 'viewed';
      }
    } else if (value === 'cancel') {
      statusValue = 'cancelled';
    }

    if (textOnly) {
      return (
        <Badge
          className={className + '-badge border-0 bg-transparent'}
          bg='none'
        >
          {locale(toWordCase(statusValue))}
        </Badge>
      );
    } else {
      return (
        <Badge
          className={className + '-badge'}
          bg='none'
        >
          {locale(toWordCase(statusValue))}
        </Badge>
      );
    }
  } else {
    return '';
  }
};

/**BOOKING DURATION FORMATTER **
 *
 * INPUT : 2.45
 * OUTPUT: 2 Hour 45 Minutes
 *
 **/

export const formatDuration = (duration) => {
  let durationText = '';
  if (!isObjectEmpty(duration) && duration.toString().includes(':')) {
    const durationArray = duration && duration.split(':');
    durationText +=
      parseInt(durationArray[0]) > 0
        ? parseInt(durationArray[0]) +
          (parseInt(durationArray[0]) === 1 ? ' hour ' : ' hours ')
        : '';
    durationText +=
      parseInt(durationArray[1]) > 0
        ? parseInt(durationArray[1]) +
          (parseInt(durationArray[1]) === 1 ? ' minute ' : ' minutes ')
        : '';
  } else {
    return duration;
  }
  return durationText;
};
const emailIcons = {
  opened: 'ri-mail-open-fill',
  sent: 'ri-mail-send-fill',
  received: 'ri-mail-unread-fill',
  draft: 'ri-mail-draft-fill',
  failed: 'ri-mail-close-fill',
  pending: 'ri-mail-settings-fill',
};

export const emailStatus = (item) => {
  let status = 'draft';
  if (item && item.emails && item.emails.length > 0) {
    item.emails.forEach((email, idx) => {
      if (email.scheduled_at === item.latest_date_time && email.incoming) {
        status = 'received';
      } else if (email.scheduled_at === item.latest_date_time) {
        status = email.status;
      } else {
        status = email.status;
      }
    });
  }

  return (
    <div className='email-icon'>
      <i className={emailIcons[status]}></i>
    </div>
  );
};

/** RETURN TRUE IF OBJECT IS EMPTY OR NOT EXIST **/
export const isObjectEmpty = (value) => {
  const valueArray = ['', ' ', null, undefined];
  return valueArray.indexOf(value) > -1;
};

export const setTeamId = (teamID) => {
  /** Set The team id id according to given condition. **/
  if (!isNaN(teamID) && !isObjectEmpty(teamID)) {
    getCurrentTeamId(teamID);
    Session.storeSessionVar('currentTeam', teamID);
    localStorage.setItem('currentTeam', teamID);
  } else {
    console.log('Warning:: Team Id Not Set!!');
  }
};

/** Fetch User Current Team data For Logged in user ***/
export const setUserDataToAuth = (userData, userSettings, callback) => {
  if (
    userData &&
    userData.authData &&
    isObjectEmpty(userData.authData.currentTeam) &&
    !hasAffiliate(userData)
  ) {
    const currentTeamData = userSettings.me.teams.filter(
      (item) => parseInt(item.id) === parseInt(currentTeamId)
    );
    const myPermissions = userSettings.getMyPermissions;
    const profilePicture =
      userSettings.getTeamUser &&
      (userSettings.getTeamUser.profile_picture ||
        userSettings.getTeamUser.profile_picture === '')
        ? userSettings.getTeamUser.profile_picture
        : userData && userData.authData && userData.authData.profile_picture
        ? userData.authData.profile_picture
        : null;

    callback({
      ...userData.authData,
      ...(currentTeamData && currentTeamData.length > 0
        ? currentTeamData[0].pivot
        : userData.authData),
      profile_picture: profilePicture,
      pin_data:
        userSettings && userSettings.me && userSettings.me.pin_data
          ? userSettings.me.pin_data
          : null,

      industry_info: userData.authData.industry_info
        ? userData.authData.industry_info
        : null,
      recommended_tasks: userData.authData.recommended_tasks
        ? userData.authData.recommended_tasks
        : null,
      token: userData.token,
      currentUserPermission: myPermissions,
      currentTeam:
        currentTeamData && currentTeamData.length > 0 ? currentTeamData[0] : '',
      email_verified_at:
        userSettings && userSettings.me && userSettings.me.email_verified_at
          ? userSettings.me.email_verified_at
          : null,
    });
  }
};

/*** Email Template Preview ***/
/**
 * Use Escape method to Prevent non encoded Char. But this method is deprecated in JS.
 * ***/
export const emailTemplateForPreview = (
  templateDetails,
  template_type = 'campaign'
) => {
  if (templateDetails) {
    const base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    let msg = null;

    try {
      msg = base64regex.test(templateDetails)
        ? window.decodeURIComponent(atob(templateDetails))
        : templateDetails;
    } catch (e) {
      msg = base64regex.test(templateDetails)
        ? window.atob(templateDetails)
        : templateDetails;
    }

    // msg = base64regex.test(msg) ? window.atob(msg) : msg;
    //msg = template_type === "campaign" ? window.atob(msg) : msg;

    return msg;
  }
};

export const dateInUtc = (date, setting = null, format24Hour = false) => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const newDateFormat = format24Hour
    ? 'YYYY-MM-DD HH:mm:ss'
    : 'YYYY-MM-DD HH:mm a';

  const zone = setting.time_zone ? setting.time_zone : moment.tz.guess();
  if (date) {
    const tm = moment(date, newDateFormat).format(dateFormat);
    const m = timeZone.tz(tm, dateFormat, zone);
    return m.utc().format(dateFormat);
    // return moment(date).utc().format(dateFormat);
  } else {
    return '';
  }
};

/*** FETCH USER SETTING ***/
export const fetchUserSettings = (teamId, callBack) => {
  teamId = !isNaN(teamId) && teamId ? teamId : currentTeamId;
  callBack({
    variables: { team_id: teamId, isTeamId: !!teamId },
    fetchPolicy: 'no-cache',
  });
};

/*** FETCH IMAGES FOR GALLERY ***/
export const imagesForGallery = (images) => {
  let arrayOfImages = [];
  if (images && images.length) {
    arrayOfImages = images.map(
      (item) =>
        !isObjectEmpty(item) && {
          id: typeof item === 'object' && item.id ? item.id : '',
          attachmentId:
            typeof item === 'object' && item.attachment
              ? item.attachment.id
              : '',
          url:
            typeof item === 'object' && item.attachment.url
              ? item.attachment.url
              : item,
        }
    );
  }
  return arrayOfImages;
};

export const videosForGallery = (videos) => {
  let arrayOfImages = [];
  if (videos && videos.length) {
    arrayOfImages = videos.map((item) => item && JSON.parse(item));
  }
  return arrayOfImages;
};
/*** IMAGES ADDED TO GALLERY ***/
export const addImagesToGallery = (
  images,
  callbacks = null,
  type = 'image'
) => {
  const videoIcon = require('../Images/media.png');
  let galleryFiles = [];
  if (images && images.length > 0) {
    galleryFiles = images.map(
      (item, idx) =>
        !isObjectEmpty(item) && (
          <li
            key={idx}
            className='text-center'
            onClick={() =>
              !isObjectEmpty(callbacks) && !isObjectEmpty(callbacks.get)
                ? callbacks.get(type === 'image' ? item.url : item)
                : () => {}
            }
          >
            {['image', 'video', 'audio'].indexOf(type) > -1 ? (
              <img
                alt=''
                src={type === 'image' ? item.url : videoIcon}
              />
            ) : (
              <i
                className={
                  fileIcons[getFileExtension(item.name).type] + ' ri-3x'
                }
              />
            )}
            {!isObjectEmpty(callbacks) && !isObjectEmpty(callbacks.remove) && (
              <span
                className='remove-thumbnail'
                onClick={() =>
                  !isObjectEmpty(callbacks.remove)
                    ? callbacks.remove(item.id)
                    : () => {}
                }
              >
                <i className='ri-close-fill'></i>
              </span>
            )}
            {type !== 'image' && (
              <div style={{ overflow: 'hidden' }}>{item.name}</div>
            )}
          </li>
        )
    );
    return galleryFiles;
  } else {
    return '';
  }
};

// get months...
export const getMonthList = () => {
  let months = [];
  for (let index = 1; index < 13; index++) {
    months.push({ label: index, value: index });
  }
  return months;
};

// get years from current year...
export const getYearList = () => {
  const currentYear = moment().format('YYYY');
  const maxYear =
    parseInt(currentYear) > 2021 ? parseInt(currentYear) + 25 : 2050;
  let years = [];
  for (
    let index = parseInt(currentYear) > 2021 ? parseInt(currentYear) : 2022;
    index < maxYear;
    index++
  ) {
    years.push({ label: index, value: index });
  }
  return years;
};

export const monthList = getMonthList();
export const yearList = getYearList();

export const hasObject = (item) => {
  if (typeof item === 'object') {
    return true;
  } else {
    return false;
  }
};

// fetch string params from url...
export const fetchUrlParams = (data) => {
  if (data.charAt(0) === '?') {
    data = data.substring(1);
  }
  const dataArray = data.split('&');
  const newData = [];
  dataArray.forEach((d) => {
    let v = d.split('=');
    if (v.length === 2) return (newData[v[0]] = v[1]);
  });
  return newData;
};

export const getCodeFromWindowURL = (url, keys = []) => {
  const popupWindowURL = new URL(url);
  let output = {};
  keys.forEach((item) => {
    const itemValue = popupWindowURL.searchParams.get(item);
    if (item && !isObjectEmpty(itemValue)) {
      output = { ...output, [item]: itemValue };
    }
  });
  return output;
};

/***INPUT ONLY NUMERIC VALUE in TEXT FIELD***/
export const onlyNumberKey = (evt) => {
  // Only ASCII character in that range allowed
  var ASCIICode = evt.which ? evt.which : evt.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
  return true;
};

//Check If Object is JSON or not
export const isJson = (item) => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === 'object' && item !== null) {
    return true;
  }

  return false;
};

/***Create HTML DIV AND INSERT CONTENT TO IT WHEN WE GET HTML IN STRING FORMAT ***/
export const createMarkup = (data, type = null) => {
  if (type === 'emoji') {
    return <span dangerouslySetInnerHTML={{ __html: data }} />;
  } else {
    return <div dangerouslySetInnerHTML={{ __html: data }} />;
  }
};
export const checkIfUserHaveTimezone = (settings) => {
  const d = settings.filter((setting) => {
    return setting.setting_name === 'time_zone';
  });
  // console.log("Here::", d);
  if (d.length > 0 && isObjectEmpty(d[0].setting_value)) {
    return true;
  } else if (d.length === 0) {
    return true;
  } else {
    return false;
  }
  // return !(d.length > 0 || isObjectEmpty(d[0].setting_value));
};

export const getConvertedDateObject = (
  userDate,
  settings,
  withFormat = true
) => {
  const dateFormat = 'YYYY-MM-DD,hh:mm a';
  const zone = settings.time_zone ? settings.time_zone : moment.tz.guess();
  if (zone) {
    const utcDate = moment.utc(userDate);
    // const convertedDate = timeZone(utcDate.format()).tz(zone);
    // console.log("Date::", userDate, utcDate.format(dateFormat), zone);
    return withFormat ? utcDate.format(dateFormat) : utcDate;
  } else {
    const m = moment().tz(zone);
    //  console.log("Date::", m.format(dateFormat), zone);
    return withFormat ? m.format(dateFormat) : m;
  }
};

export const getDateTimeFromUserTimezone = (settings) => {
  const dateFormat = 'YYYY-MM-DD,hh:mm a';
  const zone = settings.time_zone ? settings.time_zone : moment.tz.guess();
  if (zone) {
    // const utcDate = moment.utc().format();
    const m = timeZone().tz(zone);
    //    console.log("Date::", m.format(dateFormat), zone);
    return m.format(dateFormat).split(',');
  } else {
    return '';
  }
};

export const rgbToHex = (color) => {
  if (color && color.includes('#')) {
    return color;
  } else {
    if (color.toString() === 'rgb(0, 0, 0)') {
      return '#ffffff';
    } else if (color.toString() === 'rgba(0, 0, 0, 0)') {
      return 'transparent';
    } else {
      let sep = color.indexOf(',') > -1 ? ',' : ' ';
      color = color.substr(4).split(')')[0].split(sep);
      let r = (+color[0]).toString(16),
        g = (+color[1]).toString(16),
        b = (+color[2]).toString(16);
      //a = Math.round(+color[3] * 255).toString(16);

      if (r.length === 1) r = '0' + r;
      if (g.length === 1) g = '0' + g;
      if (b.length === 1) b = '0' + b;
      //if (a.length === 1) a = "0" + a;
      return '#' + r + g + b;
    }
  }
};

export const validateUrl = (value) => {
  const webUrl =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
  // const webUrl =
  // /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-_%-\/]+&?)?$/;
  let url;
  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }
  if (url.protocol === 'http:' || url.protocol === 'https:') {
    // const regex = new RegExp(webUrl);
    const redirectUrlValidate = value.match(webUrl);
    return redirectUrlValidate !== null;
  } else {
    return false;
  }
};

export const checkModPermission = (lt, index) => {
  const decodedLt = window.atob(lt);

  let ltData = [];
  if (decodedLt !== `[]` && decodedLt !== `""`) {
    ltData = JSON.parse(JSON.parse(decodedLt));
  }
  return ltData[index] && ltData[index] === 'yes' ? ltData[index] : false;
};

export const checkRoutePermission = (lt, index) => {
  const decodedLt = window.atob(lt);
  let ltData = [];
  if (decodedLt !== `[]` && decodedLt !== `""`) {
    ltData = JSON.parse(JSON.parse(decodedLt));
  }
  // console.log("DD::", ltData[index]);
  return ltData && ltData[index] ? ltData[index] : null;
};

export const getModPermission = (lt, index) => {
  const decodedLt = window.atob(lt);
  let ltData = [];
  if (decodedLt !== `[]` && decodedLt !== `""`) {
    ltData = JSON.parse(JSON.parse(decodedLt));
  }
  return ltData[index] ? ltData[index] : '';
};

export const defaultRole = ['Owner', 'Admin', 'Manager', 'Executive'];

export const getNotificationContent = (description, locale) => {
  const backendMessage = description.split('eq21');
  if (backendMessage.length > 1) {
    let parsed = null;
    try {
      parsed = JSON.parse(backendMessage[1]);
    } catch (e) {
      // Oh well, but whatever...
    }

    return locale('messages:notification.' + backendMessage[0], {
      ...(parsed ? parsed : {}),
    });
  } else {
    if (
      backendMessage[0].includes('Payment Error - No such customer:') === true
    ) {
      backendMessage[0] = 'Payment Error - No such customer';
    }
    return locale(
      'messages:notification.' +
        backendMessage[0].replace('Contact Assigned.', 'Contact Assigned')
    );
  }
};

/***CHECk IF LOGGED IN USER IS  AFFILIATE ***/
export const hasAffiliate = (userData = null) => {
  if (
    localStorage.isAffiliate ||
    getCookie('isAffiliate') ||
    (userData &&
      userData.authenticated &&
      userData.authData &&
      userData.authData.slug &&
      userData.authData.unique_id)
  ) {
    return true;
  } else {
    return false;
  }
};

export const hasClient = (userData = null) => {
  if (
    userData &&
    userData.authenticated &&
    userData.authData &&
    userData.authData.role &&
    userData.authData.role.role_type === 'client'
  ) {
    return true;
  } else {
    return false;
  }
};

export const getLanguage = (setting) => {
  let language = 'en';
  if (
    setting &&
    (!isObjectEmpty(setting.language) || !setting.language === 'english')
  ) {
    language = setting.language;
  }
  return language;
};

/** This Methods Remove State from History**/
export const clearStateFromHistory = (prevState = null, page = null) => {
  const history = createBrowserHistory();
  if (history.location.state) {
    let state = { ...history.location.state };
    if (page === 'campaign') {
      history.replace({
        ...history.location,
        state: prevState
          ? {
              campaignId: prevState.campaignId,
              campaignName: prevState.campaignName,
            }
          : {},
      });
    } else {
      history.replace({
        ...history.location,
        state: prevState
          ? { id: prevState.id, industry: prevState.industry }
          : {},
      });
    }
  }
};

export const findAndReplaceUrl = (text, newUrl = false) => {
  const modifiedText = text.replace(urlRegex, function (url) {
    if (newUrl) {
      var hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" >' + url + '</a>';
    } else {
      return '';
    }
  });
  return modifiedText;
};

export const findUrlInText = (text, channel = null) => {
  if (!text) {
    return '';
  } else {
    let tagsUrl = '';
    let modifiedText = '';

    /*** URL CONVERT TO HYPERLINK ****/
    modifiedText = text.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" >' + url + '</a>';
    });
    /*** LINE BREAK ***/
    modifiedText = modifiedText.replace(/\n/g, ' <br/> ');
    /***TAGS CONVERT TO HYPERLINK ****/
    if (channel === 'instagram') {
      tagsUrl = 'https://www.instagram.com/explore/tags';
    } else if (channel === 'facebook') {
      tagsUrl = 'https://www.facebook.com/hashtag';
    } else if (channel === 'linkedin') {
      const findString = '{hashtag|\\#|';
      tagsUrl = 'https://www.linkedin.com/feed/hashtag/?keywords=';

      modifiedText = modifiedText.replaceAll(findString, '#');
    }

    modifiedText = modifiedText.replace(hashTagRegex, function (tag) {
      let myTag = tag;

      if (channel === 'linkedin') {
        myTag = tag.replace('}', '');
      }

      let newTag = myTag.replace('#', '').trim('');
      if ('linkedin') {
        return (
          '<a href="' +
          tagsUrl +
          newTag +
          '" target="_blank" >' +
          myTag +
          '</a>'
        );
      } else {
        return (
          '<a href="' +
          tagsUrl +
          '/' +
          newTag +
          '" target="_blank" >' +
          myTag +
          '</a>'
        );
      }
    });
    let type = 'emoji';
    if (channel === 'whatsapp') {
      type = null;
    }
    return createMarkup(modifiedText, type);
  }
};

export const publicRoutesName = [
  '/signup',
  '/sign-in',
  '/checkout/',
  '/bookings/',
  '/reset-password',
  '/activate',
  '/renew/',
  '/invoice/',
  '/quote/',
  '/forgot-password/',
  '/reset/password/',
  '/unsubscribe/',
  '/subscription/',
  '/renew-subscription/',
  '/callbacks/',
  '/schedule-appointment/',
  '/cancel-appointment/',
  '/reschedule-appointment/',
  '/appointment/',
  '/form/',
  '/agreement-signing/',
  '/estimate-signing/',
];

export const handleCursorPosition = (ctrl) => {
  // console.log("Here:;", ctrl);
  let position = {
    start: 0,
    end: 0,
  };
  if (document.selection) {
    ctrl.focus();
    var range = document.selection.createRange();
    var rangeLen = range.text.length;
    range.moveStart('character', -ctrl.value.length);
    var start = range.text.length - rangeLen;
    position = {
      start: start,
      end: start + rangeLen,
    };
  } // IE >=9 and other browsers
  else if (ctrl.selectionStart || ctrl.selectionStart === '0') {
    position = {
      start: ctrl.selectionStart,
      end: ctrl.selectionEnd,
    };
  } else {
    position = {
      start: 0,
      end: 0,
    };
  }
  return position;
};

export const setCaretPosition = (ctrl, position) => {
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(position.start, position.end);
  }
  // IE < 9
  else if (ctrl.createTextRange) {
    var range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd('character', position.end);
    range.moveStart('character', position.start);
    range.select();
  }
};

export const urlToBinaryFile = (url) => {
  let file = null;
  fetch(url)
    .then((resp) => {
      console.log('FILE::', resp);
      resp.blob();
    })
    .then((blob) => {
      file = window.URL.createObjectURL(blob);
      console.log('FILE::', file);
    });
};
export const creditsListing = [
  { value: '5', label: '100 Credits', credits: '100' },
  { value: '10', label: '200 Credits', credits: '200' },
  { value: '15', label: '300 Credits', credits: '300' },
  { value: '20', label: '400 Credits', credits: '400' },
  { value: '25', label: '500 Credits', credits: '500' },
  { value: '30', label: '600 Credits', credits: '600' },
  { value: '35', label: '700 Credits', credits: '700' },
  { value: '40', label: '800 Credits', credits: '800' },
  { value: '45', label: '900 Credits', credits: '900' },
  { value: '50', label: '1000 Credits', credits: '1000' },
  { value: '100', label: '2000 Credits', credits: '2000' },
  { value: '150', label: '3000 Credits', credits: '3000' },
  { value: '200', label: '4000 Credits', credits: '4000' },
  { value: '250', label: '5000 Credits', credits: '5000' },
  { value: '300', label: '6000 Credits', credits: '6000' },
  { value: '350', label: '7000 Credits', credits: '7000' },
  { value: '400', label: '8000 Credits', credits: '8000' },
  { value: '450', label: '9000 Credits', credits: '9000' },
  { value: '500', label: '10000 Credits', credits: '10000' },
];

export const adminFilterTrigger = [
  'verified_email',
  'recommended_tasks',
  'people_received_email',
  'last_login',
  // "referer_domain",
];

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (
  bytes,
  si = false,
  dp = 1,
  currentCompanySetting
) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return (
    getNumberByUserFormat(currentCompanySetting, bytes.toFixed(dp)) +
    ' ' +
    units[u]
  );
};

/*** On Stop Type ****/
export const debounceMessage = debounce((value, callBack) => {
  callBack(value);
}, 500);

export const getAfterMailMergeValue = (input, value) => {
  let prvPosition = handleCursorPosition(input);

  let myValue = input.value;

  const beforeCurserText = myValue.substring(0, prvPosition.start);
  const afterCurserText = myValue.substring(prvPosition.end, myValue.length);

  const newPosition = {
    start: prvPosition.start + value.length,
    end: prvPosition.end + value.length,
  };

  myValue = beforeCurserText.concat(value, afterCurserText);
  input.value = myValue;
  setCaretPosition(input, newPosition);
  return myValue;
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

/** Check and validate the phone number length **/
export const validatePhoneNumber = (value, withCountryCode = true) => {
  const phoneRegExp =
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2,7}$/im;
  let phoneNumber = null;
  const interNationalFormat = formatPhoneNumberIntl(value).split(' ');
  let countryCode = null;

  if (interNationalFormat.length > 0) {
    countryCode = interNationalFormat[0];
  }

  if (
    withCountryCode &&
    !isObjectEmpty(value) &&
    value.length >= 10 &&
    value.length <= 15 &&
    countryCode
  ) {
    phoneNumber = value.substring(countryCode.length, value.length);

    // phoneNumber = value.substring(value.length - 10, value.length);
  } else if (!withCountryCode && !isObjectEmpty(value) && value.length === 10) {
    phoneNumber = value;
  } else {
    return false;
  }

  if (phoneNumber.length >= 8 && phoneRegExp.test(phoneNumber)) {
    return true;
  } else {
    return false;
  }
};

/**This Methods create a range of numeric value with exclude specific values functionality. **/
export const generateNumbers = (length, excludedOptions) => {
  const arr = [];
  for (let value = 0; value < length; value++) {
    if (excludedOptions.indexOf(value) < 0) {
      arr.push(value);
    }
  }
  return arr;
};

export const generateNumbersBetweenValues = (start, end, type = 'number') => {
  let startValue = start;
  let endValue = end;
  let numberArr = [];

  if (['seconds', 'minutes'].indexOf(type) > -1) {
    startValue = 0;
    endValue = 59;
  }
  /*******************************************************/
  if (type === 'hour') {
    const excludesHours = generateNumbersBetweenValues(startValue, endValue);
    // return excludesHours;
    return generateNumbers(24, excludesHours);
  } else {
    if (startValue > endValue) {
      numberArr = new Array(startValue - endValue + 1);
      for (var i = 0; i < numberArr.length; i++, startValue--) {
        numberArr[i] = startValue;
      }
      // console.log("First::", numberArr);
      return numberArr;
    } else {
      numberArr = new Array(endValue - startValue + 1);
      for (var j = 0; j < numberArr.length; j++, startValue++) {
        numberArr[j] = startValue;
      }

      return numberArr;
    }
  }
};

export const checkContactHasName = (contact) => {
  let contactName = contact.name;
  if (isObjectEmpty(contactName) && !isObjectEmpty(contact.email)) {
    contactName = contact.email;
  } else if (isObjectEmpty(contactName) && !isObjectEmpty(contact.phone)) {
    contactName = contact.phone;
  }
  return contactName;
};

export const countryDetection = () => {
  var userRegion;
  var userCity;
  var userCountry;
  var userTimeZone;
  var userCountryCode;
  var timeZoneCityToCountry = getTimezoneList();
  var languageDetected;

  if (Intl) {
    userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // fetch(`https://ipapi.co/json`)
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(function (data) {
    //     var ipTimezone = data.timezone;
    //     console.log(
    //       `browser timezone: ${userTimeZone}`,
    //       `ip timezone: ${ipTimezone}`
    //     );
    //     console.log("Browser From VPN::", {
    //       browser: userTimeZone,
    //       ip: ipTimezone,
    //       usingVPN: ipTimezone !== userTimeZone,
    //     });
    //   });

    var tzArr = userTimeZone.split('/');
    userRegion = tzArr[0];
    userCity = cityNames(tzArr[tzArr.length - 1]);

    userCountry =
      timeZoneCityToCountry[userCity] && timeZoneCityToCountry[userCity].country
        ? timeZoneCityToCountry[userCity].country
        : null;
    userCountryCode =
      timeZoneCityToCountry[userCity] && timeZoneCityToCountry[userCity].code
        ? timeZoneCityToCountry[userCity].code
        : 'US';

    languageDetected =
      userCountry && !isObjectEmpty(countryWiseLanguage[userCountry])
        ? countryWiseLanguage[userCountry]
        : 'en';
  }

  // console.log("Time Zone:", userTimeZone);
  // console.log("Region:", userRegion);
  // console.log("City:", userCity);
  // console.log("Country:", userCountry);
  // console.log("userCountryCode:", userCountryCode);
  // console.log("timeZoneCityToCountry", timeZoneCityToCountry);
  // console.log("languageDetected:", languageDetected);

  return {
    region: userRegion,
    city: userCity,
    country: userCountry,
    timezone: userTimeZone,
    countryCode: userCountryCode,
    languageDetected: languageDetected,
  };
};

export const getTimezoneList = () => {
  const { countries, zones } = require('moment-timezone/data/meta/latest.json');
  const timeZoneCityToCountry = {};

  Object.keys(zones).forEach((z) => {
    const cityArr = z.split('/');
    const city = cityArr[cityArr.length - 1];
    timeZoneCityToCountry[city] = {
      country: countries[zones[z].countries[0]].name,
      code: countries[zones[z].countries[0]].abbr,
    };
  });
  return timeZoneCityToCountry;
};

/** This Method return city(Old|New) name if change in Timezone ***/
export const cityNames = (cityName) => {
  const cityNames = {
    Calcutta: 'Kolkata',
  };

  if (isObjectEmpty(cityNames[cityName])) {
    return cityName;
  } else {
    return cityNames[cityName];
  }
};
export const getNumberOfRecordToBeShow = (type = 'Contact') => {
  const name = toSnackCase(type).concat('_record_show');
  return Session.getSessionVar(name)
    ? parseInt(Session.getSessionVar(name))
    : 10;
};

export const settingMenu = [
  {
    name: 'basic_settings',
    path: 'basic-setting',
  },
  {
    name: 'team_members',
    path: 'team-members',
  },
  {
    name: 'role_permissions',
    path: 'roles',
  },
  {
    name: 'calling_sms',
    path: 'phone-configuration',
  },
  {
    name: 'credit_history',
    path: 'credits-history',
  },
  {
    name: 'email_configuration',
    path: 'email-configuration',
  },
  {
    name: 'api',
    path: 'api',
  },
  {
    name: 'plugins',
    path: 'plugins',
  },
  {
    name: 'app-management',
    path: 'app-management',
  },
  {
    name: 'tracking_script',
    path: 'tracking-script',
  },
  {
    name: 'custom_fields',
    path: 'custom-fields',
  },
  {
    name: 'payment_gateway',
    path: 'payment-gateway',
  },
  {
    name: 'emails',
    path: 'emails',
  },
  {
    name: 'landing_pages',
    path: 'landing-pages',
  },
  {
    name: 'taxes',
    path: 'taxes',
  },
  {
    name: 'scoring',
    path: 'scoring-management',
  },
  {
    name: 'tags',
    path: 'tag-management',
  },
  {
    name: 'bookings',
    path: 'booking-preference',
  },
  {
    name: 'bookings',
    path: 'create-booking-preference',
  },
  {
    name: 'account_billing',
    path: 'accounts-and-billing',
  },
  {
    name: 'affiliates',
    path: 'affiliates',
  },
  {
    name: 'announcements',
    path: 'announcements',
  },
  {
    name: 'filter_sources',
    path: 'filters-and-sources',
  },
  {
    name: 'forms',
    path: 'form-management',
  },
  {
    name: 'tasks',
    path: 'tasks',
  },
  {
    name: 'agreements',
    path: 'agreements',
  },
  {
    name: 'estimates',
    path: 'estimates',
  },
  // {
  //   name: "magic-builder",
  //   path: "magic-builder",
  // },
  // {
  //   name: "whatsapp",
  //   path: "whatsapp-management",
  // },
  {
    name: 'chatbot',
    path: 'chatbot',
  },
];

/***NEw Page Permission set here ***/
export const getAllowedPages = (userData) => {
  if (userData.authData && userData.authData.currentTeam) {
    let allPagesAllowed = checkRoutePermission(
      userData.authData.currentTeam.lt,
      'allowed_pages'
    );
    allPagesAllowed =
      allPagesAllowed && allPagesAllowed.length > 0 ? allPagesAllowed : [];
    return allPagesAllowed;
  } else {
    return [];
  }
};

export const isPermissionAvailable = (userData, key) => {
  if (
    userData &&
    userData.authData &&
    userData.authData.currentTeam &&
    checkModPermission(userData.authData.currentTeam.lt, key)
  ) {
    return true;
  } else {
    return false;
  }
};

export const groupIcons = {
  CRM: 'ri-contacts-book-2-line',
  Pipeline: 'ri-flow-chart',
  Campaign: 'ri-volume-vibrate-line',
  Workflow: 'ri-organization-chart',
  Dashboard: 'ri-dashboard-line',
  Catalogue: 'ri-store-3-line',
  Social: 'ri-store-3-line',
  Appointment: 'ri-calendar-line',
  Conversation: 'ri-chat-3-line',
};
/** Get Notification Redirect URL **/
export const exceptNotificationTypes = [
  'contact_import',
  'import_error',
  'tracking_notification',
  'inventory_consumption',
  'deal_created',
  'deal_assigned',
  'deal_creation',
  'document_requested',
];
export const getRedirectUrl = (notification, industry, isClient = false) => {
  const types = {
    deal: ['deal_created', 'deal_assigned', 'deal_creation'],
    task: ['total_todays_task', 'task_appointment'],
    contact: [
      'contact_assigned',
      'contact_lifecycle_stage_change',
      'contact_hot_warm_lead',
      'form_submission',
      'contact_visits_website',
      'email_received',
      'document_added',
      'note_created',
      'payment_failed',
      'appointment_reminder',
      'appointment_create',
      'appointment_update',
      'booking_purchased',
      'message_received',
      'note_creation',
      'document_requested',
      'document_uploaded',
    ],
    quote: ['quote_created_updated', 'last_week_saved_quotes'],
    project: ['invoice_paid', 'invoice_created_updated'],
    invoice: ['invoice_paid', 'invoice_created_updated'],
    payment: ['payment_failed'],
    import: ['contact_import', 'import_error'],
    inventory: ['inventory_consumption'],
    tracking: ['tracking_notification'],
    agreement: ['agreement'],
    estimate: ['estimate'],
  };

  const nId = notification.id;
  const nType = notification.notification_type;
  const {
    document_request_id,
    contact_id,
    task_id,
    appointment_id,
    deal_id,
    pipeline_id,
    quote_id,
    more_info,
    agreement_id,
    estimate_id,
  } = notification.data ? notification.data : {};

  if (types.deal.indexOf(nType) > -1) {
    if (deal_id && pipeline_id) {
      return {
        to: '/pipeline',
        state: {
          id: deal_id,
          nId: nId,
          redirectPageParams: {
            id: pipeline_id,
          },
        },
      };
    } else {
      return {
        to: '/pipeline',
      };
    }
  } else if (
    [
      ...types.task,
      ...types.contact,
      ...types.invoice,
      ...types.project,
      ...types.quote,
      ...types.agreement,
      ...types.estimate,
    ].indexOf(nType) > -1
  ) {
    let id = null;
    let activeTab = 'activities';
    let subTab = '';
    let pathName = isClient ? '/client-dashboard' : '/contact-details';
    if (!isObjectEmpty(task_id)) {
      id = task_id;
      activeTab = 'tasks';
      if (isObjectEmpty(contact_id)) {
        pathName = '/settings/tasks';
      }
    } else if (!isObjectEmpty(appointment_id)) {
      id = appointment_id;
      activeTab = 'appointments';
    } else if (nType === 'agreement') {
      id = agreement_id;
      activeTab = 'agreement';
    } else if (nType === 'estimate') {
      id = estimate_id;
      activeTab = 'estimate';
    } else if (nType === 'booking_purchased' && !isObjectEmpty(contact_id)) {
      id = contact_id;
      activeTab = 'appointments';
      subTab = 'bookings';
    } else if (!isObjectEmpty(quote_id)) {
      id = quote_id;
      if (types.invoice.indexOf(nType) > -1 && industry === 'saas') {
        activeTab = 'invoice';
      } else if (types.project.indexOf(nType) > -1 && industry === 'service') {
        activeTab = 'projects';
      } else {
        activeTab = 'quotes';
      }
    } else if (nType === 'email_received') {
      activeTab = 'communication';
    } else if (nType === 'message_received') {
      activeTab = 'communication';
      subTab = 'messageTab';
    } else if (nType === 'note_creation') {
      activeTab = 'notes';
    } else if (
      ['document_requested', 'document_uploaded'].indexOf(nType) > -1
    ) {
      activeTab = 'documents';
      id = document_request_id;
    } else {
      if (types.invoice.indexOf(nType) > -1 && industry === 'saas') {
        activeTab = 'invoice';
      } else if (types.project.indexOf(nType) > -1 && industry === 'service') {
        activeTab = 'projects';
      }
    }

    return {
      to: pathName,
      state: {
        id: contact_id,
        nId: nId,
        industry: industry,
        redirectPageParams: { activeTab: activeTab, id: id, subTab: subTab },
      },
    };
  } else if (types.import.indexOf(nType) > -1) {
    return {
      pathname: null,
      nId: nId,
      importData: more_info,
      notificationType: nType,
    };
  } else if (types.inventory.indexOf(nType) > -1) {
    return {
      to: '/settings/accounts-and-billing',
      state: {
        nId: nId,
        industry: industry,
        redirectPageParams: {
          activeTab: 'accounts-and-billing',
          subTab: 'plan',
        },
      },
    };
  } else if (types.tracking.indexOf(nType) > -1) {
    return {
      to: '/settings/tracking-script',
      state: {
        nId: nId,
        industry: industry,
        redirectPageParams: {
          activeTab: 'tracking-script',
        },
      },
    };
  } else {
    return {
      nId: nId,
      to: '/',
    };
  }
};

export const stripHtml = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const compareAndSort = (a, b) => {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
};

export const fileExtension = {
  jpg: 'image',
  jpeg: 'image',
  svg: 'image',
  bmp: 'image',
  png: 'image',
  xls: 'excel',
  csv: 'excel',
  txt: 'text',
  xlsx: 'excel',
  doc: 'document',
  docx: 'document',
  zip: 'archive',
  rar: 'archive',
  exe: 'executable',
  pdf: 'pdf',
  ppt: 'powerPoint',
  pptx: 'powerPoint',
};

export const fileIcons = {
  image: 'ri-image-line ri-lg me-2 text-primary ',
  text: 'ri-file-text-line ri-lg me-2 text-primary ',
  excel: 'ri-file-excel-2-line ri-lg me-2 text-success ',
  document: 'ri-file-word-2-line ri-lg me-2 text-primary ',
  archive: 'ri-inbox-unarchive-line ri-lg me-2 text-primary ',
  executable: 'ri-file-excel-2-line ri-lg me-2 text-secondary ',
  pdf: 'ri-file-pdf-line ri-lg me-2 text-danger ',
  powerPoint: 'ri-file-ppt-2-line ri-lg me-2 text-danger ',
  none: 'ri-question-fill ri-lg me-2 text-primary ',
};

export const hasPassed24Hours = (dateTime) => {
  const lastExecutionTime = moment.utc(dateTime).format(); //your last execution time
  const nextDay = moment(lastExecutionTime).add(1, 'day').utc().format(); // calculate 24 hours next to last access
  const currentTime = moment().utc().format(); //current time
  const canExecuteNow = !moment(currentTime).isSameOrAfter(nextDay);
  // console.log(dateTime, lastExecutionTime, currentTime, canExecuteNow);
  return canExecuteNow;
};

export const hasTextContainsUrl = (text) => {
  let count = 0;
  if (text && text.length > 0) {
    text.replace(urlRegex, function (url) {
      count++;
    });
  }

  return count > 0;
};

/***Get File Extension **/
export const getFileExtension = (filename) => {
  const extensionText = filename.split('.').pop();
  const extension = extensionText ? extensionText.toLowerCase() : '';
  return {
    ext: extensionText.toLowerCase(),
    type: fileExtension[extension] ? fileExtension[extension] : 'none',
    className: fileIcons[fileExtension[extension]]
      ? fileIcons[fileExtension[extension]]
      : '',
  };
};
export const isInIframe = () => {
  return window.top !== window.self;
};

export const dateObjToMoment = (dateString = '', format = 'YYYY-MM-DD') => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  const day = date.getDate();
  month = month.toString().length === 1 ? '0' + month : month;
  const formattedDate = `${year}-${month}-${day}`;
  const val = moment(formattedDate).format(format);
  return moment(formattedDate).format(format);
};

export const customAttribute = [
  { value: 'text', label: 'Text' },
  { value: 'integer', label: 'Integer' },
  { value: 'decimal', label: 'Decimal' },
  { value: 'date', label: 'Date' },
  { value: 'time', label: 'Time' },
  { value: 'percentage', label: 'Percentage' },
  { value: 'email', label: 'Email' },
  { value: 'phone', label: 'Phone' },
  { value: 'url', label: 'Url' },
  { value: 'textarea', label: 'Textarea' },
  { value: 'dropdown', label: 'Dropdown' },
  { value: 'checkbox', label: 'Checkbox' },
  { value: 'radio_button', label: 'Radio' },
];

export const importForType = {
  CSV: 0,
  FACEBOOK: 1,
  TWITTER: 2,
  LINKEDIN: 3,
  GMAIL: 4,
  OUTLOOK: 5,
  IMAP: 6,
};

export const moduleType = {
  CONTACTS: 0,
  PRODUCTS: 1,
  SERVICES: 2,
  CATEGORIES: 3,
  EMAILS: 4,
  DEALS: 5,
};

export const connectorType = { OutlookContact: 'OUTLOOK' };

export const tableAttributeFor = {
  deals_listing: 'deals',
  contacts_listing: 'contacts',
};
export const tableAttributesInGroups = (allFields = [], locale) => {
  let filtersAttributes = [];
  let itemGroup = [];

  if (allFields && allFields.length > 0) {
    filtersAttributes = allFields.filter((item) => item.value === 'default');
    allFields.forEach((filter, index) => {
      if (
        itemGroup.indexOf(filter.module) === -1 &&
        filter.value !== 'default'
      ) {
        itemGroup.push(filter.module);
        filtersAttributes.push({
          label: locale(filter.module.toLowerCase()),
          options: [],
        });
      }
    });

    if (filtersAttributes && filtersAttributes.length > 0) {
      filtersAttributes.forEach((group, index) => {
        allFields.forEach((filter) => {
          if (filter.value !== 'default') {
            if (locale(filter.module.toLowerCase()) === group.label) {
              group.options.push({
                ...filter,
                label:
                  filter.group !== 'custom attribute'
                    ? locale(filter.label.toLowerCase())
                    : filter.label,

                group: filter.group,
                module: filter.module,
              });
            }
          }
        });
      });
    }
  }
  // console.log("filtersAttributes:", filtersAttributes);
  return filtersAttributes;
};

export const getUserTeams = (userData) => {
  if (userData && userData.authData && userData.authData.teams) {
    return userData.authData.teams;
  } else {
    return [];
  }
};

export const loggedInUserId = (userData) => {
  return userData && userData.authData && parseInt(userData.authData.id);
};
export const getLoggedInUser = (userData) => {
  return userData && userData.authData && userData.authData.name;
};

export const getTimeStamp = (timestamp, type = 'seconds') => {
  const date = new Date(timestamp);
  const seconds = Math.floor(date.getTime() / 1000); // Convert milliseconds to seconds
  const unixTimestamp = seconds - new Date().getTimezoneOffset() * 60;
  if (type === 'seconds') {
    return seconds;
  } else if (type === 'unix') {
    return unixTimestamp;
  }
};

export const getPermission = (permissions, name) => {
  const permissionKeys = permissions ? Object.keys(permissions) : [];
  if (permissions && permissions[name] && permissions[name].isAllow) {
    return true;
  } else if (
    permissions &&
    permissionKeys.filter((key) => key.includes(name)).length > 0
  ) {
    return true;
  } else {
    return false;
  }
};

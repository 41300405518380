import React, { createContext, useMemo, useState } from "react";

export const InventoryConsumption = createContext(null);

export const InventoryConsumptionProvider = ({ children }) => {
    const [showBuyAddonPlan, setShowBuyAddonPlan] = useState(false);
    const [inventoryType, setInventoryType] = useState("");
    const [buttonSelection, setButtonSelection] = useState(null);
    const [upgradeAvailableFor, setUpgradeAvailableFor] = useState(null);
    const [redirectTo, setRedirectTo] = useState(false);
    const value = useMemo(() => ({
        showBuyAddonPlan,
        setShowBuyAddonPlan,
        inventoryType,
        setInventoryType,
        upgradeAvailableFor,
        setUpgradeAvailableFor,
        buttonSelection, setButtonSelection,
        redirectTo, setRedirectTo
    }), [
        showBuyAddonPlan,
        setShowBuyAddonPlan,
        inventoryType,
        setInventoryType,
        upgradeAvailableFor,
        setUpgradeAvailableFor,
        buttonSelection, setButtonSelection,
        redirectTo, setRedirectTo
    ]);
    // Contact/Campaigns/Work Flows/Users/Accounts/Bookings/Deals/Space (Documents/Gallery)
    return <InventoryConsumption.Provider value={value}>
        {children}
    </InventoryConsumption.Provider>;
}

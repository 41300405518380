import { gql } from "@apollo/client";

/** Offering CReate / Update **/
export const G2_REVIEW = gql`
  mutation getG2ReviewForm($team_id: Int) {
    getG2ReviewForm(team_id: $team_id) {
      url
    }
  }
`;

export const G2_REVIEW_SUBMIT = gql`
  mutation setReviewStatus($team_id: Int, $status: String) {
    setReviewStatus(team_id: $team_id, status: $status) {
      status
    }
  }
`;

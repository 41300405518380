import React, { createContext, useState } from "react";

export const ModulePermission = createContext(null);

export const ModulePermissionProvider = ({ children }) => {
  const [allowedPages, setAllowedPages] = useState([]);
  const [onPage, setOnPage] = useState(false);
  /*
   * SHOW | HIDE URL & BUTTON's IF User Doesn't Have Permission
   */
  const [isShowAll] = useState(true);

  const value = {
    allowedPages,
    setAllowedPages,
    onPage,
    setOnPage,
    isShowAll,
  };

  return (
    <ModulePermission.Provider value={value}>
      {children}
    </ModulePermission.Provider>
  );
};

import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { Media } from "../Media/Media";

const alertType = {
  error: { variant: "danger", icon: "ri-alert-line", title: "" },
  success: {
    variant: "success",
    icon: "ri-checkbox-circle-line",
    title: "Success",
  },
  warning: { variant: "warning", icon: "ri-alert-line", title: "Warning!" },
  info: { variant: "danger", icon: "ri-information-line", title: "" },
};

const XAlertMessage = ({
  type,
  message,
  alertShow,
  setAlertShow,
  timeToWait = 5000,
  maxWidth="600px",
}) => {
  useEffect(() => {
    if (alertShow) {
      setTimeout(() => {
        setAlertShow(false);
      }, timeToWait);
    }
    return () => {
      setTimeout(() => {
        setAlertShow(false);
      }, timeToWait);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertShow, setAlertShow]);

  /** Style **/
  const createMarkup = (data) => {
    return { __html: data };
  };
  return (
    <React.Fragment>
      <div
        className="alert-outer-main"
        style={{
          minWidth: "300px",
          maxWidth: {maxWidth},
          // position: "absolute",
          background: "#fff",
          zIndex: 99999,
        }}
      >
        {alertShow && type && (
          <Alert
            variant={alertType[type].variant}
            onClose={() => setAlertShow(false)}
            dismissible={maxWidth==="600px" && timeToWait < 99999999}
            className="mb-0"
          >
            <Media>
              <i className={`${alertType[type].icon} ri-2x me-3`}></i>
              <Media.Body>
                <Alert.Heading className={"mb-0 font-weight-bold"}>
                  {alertType[type].title}{" "}
                  <span
                    className="small"
                    dangerouslySetInnerHTML={createMarkup(message)}
                  />
                </Alert.Heading>
              </Media.Body>
            </Media>
          </Alert>
        )}
      </div>
    </React.Fragment>
  );
};

XAlertMessage.propTypes = {
  type: PropTypes.string,
  message: PropTypes.any,
  alertShow: PropTypes.bool,
  setAlertShow: PropTypes.func,
};

export default XAlertMessage;

import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const alertType = {
  error: { variant: "danger", icon: "ri-close-line", title: "Error!" },
  success: {
    variant: "success",
    icon: "ri-checkbox-circle-line",
    title: "Success",
  },
  warning: { variant: "warning", icon: "ri-alert-line", title: "Warning!" },
  confirm: {
    variant: "warning",
    icon: "ri-close-line",
    title: "Confirmation",
  },
};

let message = "are you sure you want to delete";

const ConfirmationMessage = ({
  confirmation,
  setConfirmation,
  callback,
  page = "all",
  teamUser = [],
  roleList = [],
  uploadCategory = [],
  buttonTitle = "Delete",
}) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const [assignTo, setAssignTo] = useState(null);
  const [description, setDescription] = useState("");
  const createMarkup = (data) => {
    return { __html: data };
  };

  useEffect(() => {
    if (!confirmation.message || confirmation.message === "") {
      setDescription(
        locale("this will delete the") +
          " " +
          locale(confirmation.recordType.toLowerCase()) +
          " " +
          locale("and it's relevant data")
      );
    } else if (confirmation.activeContactsCount) {
      setDescription(
        locale(confirmation.message.toLowerCase(), {
          count: confirmation.activeContactsCount,
        })
      );
    } else {
      setDescription(locale(confirmation.message.toLowerCase()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation]);

  const handleClick = () => {
    if (hasDisabled()) {
      //Do Nothing..
    } else {
      callback(confirmation.value, parseInt(assignTo));
      setConfirmation({ ...confirmation, visible: false });
    }
  };

  const hasDisabled = () => {
    if (
      page === "team-member" &&
      assignTo === null &&
      contactAssigned &&
      parseInt(contactAssigned.contact_assigned) > 0
    ) {
      return true;
    } else if (
      page === "upload-category" &&
      assignTo === null &&
      categoryAssigned &&
      parseInt(categoryAssigned.documents_count) > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const contactAssigned = teamUser.filter(
    (item) => item && parseInt(item.value) === parseInt(confirmation.value)
  )[0];

  const roleAssigned = roleList.filter(
    (item) => item && parseInt(item.value) === parseInt(confirmation.value)
  )[0];

  const categoryAssigned = uploadCategory.filter(
    (item) => item && parseInt(item.value) === parseInt(confirmation.value)
  )[0];

  //Render HTML
  return (
    <Modal
      className={"common"}
      show={confirmation.visible}
      backdrop="static"
      keyboard={false}
      centered
      style={{ zIndex: 9999 }}
    >
      <Modal.Header className={"bg-dark"}>
        <Modal.Title className={"text-white secondary-font text-uppercase"}>
          {locale(alertType[confirmation.type].title.toLowerCase())}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"alert-in-popup alert-in-danger"}>
          <div className={"alert-in-popup-inner"}>
            <i className={`${alertType[confirmation.type].icon} ri-3x `}></i>
            <h3 className="mt-1">
              {confirmation.heading
                ? locale(confirmation.heading.toLowerCase())
                : locale(message.toLowerCase())}
            </h3>
            <div dangerouslySetInnerHTML={createMarkup(description)} />
            {page === "team-member" &&
              contactAssigned &&
              parseInt(contactAssigned.contact_assigned) > 0 && (
                <React.Fragment>
                  <p>
                    {locale(
                      "If yes please choose a User to whom all the contacts will be assigned from this Username"
                    )}
                  </p>
                  <Row>
                    <Col md={8} className="offset-md-2 text-start">
                      <Form.Group className="form-group">
                        <Select
                          options={teamUser.filter(
                            (item) =>
                              item &&
                              parseInt(item.value) !==
                                parseInt(confirmation.value)
                          )}
                          placeholder={locale("select user")}
                          onChange={(e) => {
                            setAssignTo(e.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            {page === "roles-permission" &&
              roleAssigned &&
              parseInt(roleAssigned.no_of_users) > 0 && (
                <React.Fragment>
                  <p>
                    {locale(
                      "If yes please choose a User to whom all the contacts will be assigned from this Username"
                    )}
                  </p>
                  <Row>
                    <Col md={8} className="offset-md-2 text-start">
                      <Form.Group className="form-group">
                        <Select
                          options={roleList.filter(
                            (item) =>
                              item &&
                              parseInt(item.value) !==
                                parseInt(confirmation.value)
                          )}
                          placeholder={locale("select user")}
                          onChange={(e) => {
                            setAssignTo(e.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </React.Fragment>
              )}

            {page === "upload-category" &&
              categoryAssigned &&
              parseInt(categoryAssigned.documents_count) > 0 && (
                <React.Fragment>
                  <p>
                    {locale(
                      "If yes please choose a Category to whom all the documents will be assigned from this Category"
                    )}
                  </p>
                  <Row>
                    <Col md={8} className="offset-md-2 text-start">
                      <Form.Group className="form-group">
                        <Select
                          options={uploadCategory.filter(
                            (item) =>
                              item &&
                              parseInt(item.value) !==
                                parseInt(confirmation.value)
                          )}
                          placeholder={locale("select category")}
                          onChange={(e) => {
                            setAssignTo(e.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary btn-ms-icon"
          onClick={handleClick}
          disabled={hasDisabled()}
        >
          <i className={"ri-delete-bin-5-line font-weight-normal"}></i>{" "}
          <span>
            {locale(
              confirmation.buttonTitle
                ? confirmation.buttonTitle.toLowerCase()
                : buttonTitle.toLowerCase()
            )}
          </span>
        </Button>{" "}
        <Button
          variant="outline-primary btn-ms-icon"
          onClick={() => setConfirmation({ ...confirmation, visible: false })}
        >
          <i className={"ri-close-line"}></i> <span>{locale("cancel")}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmationMessage;

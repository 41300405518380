import { getSettings } from "./CurrentSetting";

/***Filter out User Company Settings from UserData Context and return ***/
export const getCompanySettings = (userData) => {
  let companySettings = null;
  if (userData) {
    if (
      userData.authData &&
      userData.authData.currentTeam &&
      userData.authData.currentTeam.settings
    ) {
      companySettings = getSettings(userData.authData.currentTeam.settings);
      return companySettings;
    }
  }
  return companySettings;
};

import Cookies from "universal-cookie";

const cookies = new Cookies();

export default class Session {
  static getSessionVar(param) {
    return cookies.get(param);
  }

  static storeSessionVar(param, value, age = 2147483647, domain = false) {
    let options = {};
    if (!domain) {
      options = {
        maxAge: age,
        path: "/",
      };
    } else {
      options = {
        maxAge: age,
        domain: process.env.SESSION_DOMAIN,
      };
    }
    cookies.set(param, value, options);
  }

  static removeSessionVar(param) {
    const options = {
      maxAge: 0,
      path: "/",
    };
    cookies.remove(param, options);
  }
}
